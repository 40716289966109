import React from 'react';
import { Helmet } from "react-helmet";
import MainBanner from '../../components/Web/_MascotasEnAdopcion/MainBanner';
import Publicaciones from '../../components/Web/_MascotasEnAdopcion/Publicaciones';

export default function MascotasEnAdopcion() {
    return (
        <>
            <Helmet>
                <title>Mascotas en adopción | Mascoteando</title>   
                <meta name="description" content="En esta sección podrás publicar un aviso en caso de que tengas una mascota que quieras dar en adopción."/>\
                <meta name="keywords" content="perros en adopción, adopción de perros, perros para adoptar, mascota en adopción, gato en adopción, gatos para adoptar, adoptar perro en Montevideo, adoptar gato en Montevideo"/>
            </Helmet>

            <MainBanner />
            <Publicaciones />
        </>
    )
}