import { basePath, apiVersion } from './config';

export function signUpApi(data, tokenRecaptcha){
    const url = `${basePath}/${apiVersion}/sign-up`;

    data.tokenRecaptcha = tokenRecaptcha;

    const params = {
        method: 'POST',
        body: JSON.stringify(data),
        headers: {
            'Content-Type': 'application/json'
        }
    };

    return fetch(url, params)
            .then(response => {
                return response.json();
            })
            .then(result =>{
                if(result.user) {
                    return { ok: true, message: 'Usuario registrado correctamente' };
                }           
                return { ok: false, message: result.message };            
            })
            .catch(err => {
                return { ok: false, message: err.message };
            })
}

export function signInApi(data) {
    const url = `${basePath}/${apiVersion}/sign-in`;

    const params = {
        method: 'POST',
        body: JSON.stringify(data),
        headers: {
            'Content-Type': 'application/json'
        }
    };

    
    return fetch(url, params)
            .then(response => {
                return response.json();
            })
            .then(result =>{      
                return result;            
            })
            .catch(err => {
                return err.message;
            })
}

export function userActivationApi(token) {
    const url = `${basePath}/${apiVersion}/account-activation/${token}`;

    return fetch(url)
        .then(response => {
            return response;
        })
        .then(result => {
            return result;
        })
        .catch(err => {
            return err.message;
        });
}

export function updatePasswordApi(token, data){
    const url = `${basePath}/${apiVersion}/update-account-password/`;

    const params = {
        method: 'PUT',
        body: JSON.stringify(data),
        // body: publicationData,
        headers: {
            'Content-Type': 'application/json',
            Authorization: token
        }
        
    }

    return fetch(url, params)
    .then(response => {
        return response.json();
    })
    .then(result => {
        return result;
    })
    .catch(err => {
        return err.message;
    });
}

export function sendRecoveryEmaildApi(data){
    const url = `${basePath}/${apiVersion}/send-recovery-email/`;

    const params = {
        method: 'POST',
        body: JSON.stringify(data),
        headers: {
            'Content-Type': 'application/json'
        }
        
    }

    return fetch(url, params)
    .then(response => {
        return response.json();
    })
    .then(result => {
        return result;
    })
    .catch(err => {
        return err.message;
    });
}

export function recoverUserPassworApi(token, data) {
    const url = `${basePath}/${apiVersion}/recover-user-password/${token}`;

    const params = {
        method: 'POST',
        body: JSON.stringify(data),
        // body: publicationData,
        headers: {
            'Content-Type': 'application/json',
            Authorization: token
        }        
    }

    return fetch(url, params)
    .then(response => {
        return response.json();
    })
    .then(result => {
        return result;
    })
    .catch(err => {
        return err.message;
    });
}

export function loginFacebookApi(data) {
    const url = `${basePath}/${apiVersion}/facebook-login`;

    const params = {
        method: 'POST',
        body: JSON.stringify(data),
        headers: {
            'Content-Type': 'application/json'
        }        
    }

    return fetch(url, params)
    .then(response => {
        return response.json();
    })
    .then(result => {
        return result;
    })
    .catch(err => {
        return err.message;
    });
}