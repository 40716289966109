import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Row, Col, Card } from 'antd';
import { getMascotasEnAdopcionApi } from '../../../../api/mascotaEnAdopcion';
import { IMAGE_PATH } from '../../../../utils/constants';

export default function CardsMascotasEnAdopcion() {
    const [ mascotasData, setMascotasData ] = useState([]);
    const { Meta } = Card;
    

    useEffect(() => {
        getMascotasEnAdopcionApi().then(response => {
            const arrayMascotas = [];
            response.mp.forEach(item => {
                arrayMascotas.push(item);
            });
            setMascotasData(arrayMascotas);
        });
    }, []);


    if(!mascotasData) {
        return null;
    }

    return (
        mascotasData.length !== 0 ?
            <div className="container-publicaciones">      
                <Row>                
                    <Col lg={{ span: 16, offset: 4 }}>  
                        <Row>                                   
                            <h2>Últimas mascotas en adopción</h2>      
                            <Link to={`/mascotas-en-adopcion/`} className='ver-todas'>Ver Todas</Link>   
                        </Row>     
                        <Row className='row-publicaciones'  gutter={[15, 15]}>
                            {mascotasData.map(item => {
                                
                                const aux = IMAGE_PATH + item.petImage[0];

                                let finalized;
                                item.finalizedPublication === true ? finalized = 'finalizedPublication' : finalized = ''; 

                                return(
                                    <Col lg={6} xs={24} key={item._id}>
                                        <Link to={`/mascotas-en-adopcion/mas-detalles/?id=${item._id}`}>
                                            <Card className={finalized} cover={<><img src={aux} alt={item.petName} /><PetNotice finalized={item.finalizedPublication} /></>}>
                                                { item.petName !== 'undefined' ?
                                                    <Meta title={item.petName}/>
                                                :
                                                    <br/>
                                                }
                                                <p>Sexo: {item.petSex}</p> 
                                                <p>Departamento: {item.department}</p>
                                                <p>Barrio: {item.neighborhood}</p>
                                                
                                                {/* Se muestran los datos solo si la publicación no fue finalizada */}
                                                {
                                                item.finalizedPublication === false ? 
                                                <>
                                                    <p>Contacto: {item.contactName} {item.contactLastname}</p>
                                                    <p>Teléfono: {item.phone}</p>
                                                </> 
                                                : 
                                                    null 
                                                }
                                            </Card>   
                                        </Link>
                                    </Col>
                                );
                            })}
                        </Row>            
                    </Col>                
                </Row>            
            </div>
        :
            null
    )
}

function PetNotice (props) {
    const { finalized } = props;

    if(finalized ===  true) {
        return (
            <div className="ribbon ribbon-top-left">
                <span>Mascota adoptada</span>
            </div>
        )
    }
    else {
        return null;
    }    
}