import React from 'react';
import { useLocation } from 'react-router-dom';
import MainBanner from '../../../components/Web/_MisPublicaciones/_EditarPublicaciones/MainBanner';
import EditarMascotaPerdida from '../../../components/Web/_MisPublicaciones/_EditarPublicaciones/EditarMPerdidaForm';
import EditarMascotaEncontrada from '../../../components/Web/_MisPublicaciones/_EditarPublicaciones/EditarMEncontradaForm';
import EditarMascotaEnAdopcion from '../../../components/Web/_MisPublicaciones/_EditarPublicaciones/EditarMAdopcionForm';


export default function EditarMisPublicaciones() {
    const params = useLocation(); 
    const query = new URLSearchParams(params.search);
    
  

    // TODO: Depediendo del tipo de mascota, mostrare distintos componentes. 
    return (
        <div>
            <MainBanner publicationType={query.get('type')}/>  
            {/* <EditarMascotaPerdida petId={query.get('id')} /> */}
            <ConditionalRender petId={query.get('id')} publicationType={query.get('type')} />
        </div>        
    )
}


function ConditionalRender(props) {
    const { publicationType, petId } = props;
    
    if(publicationType === 'mp') {
        return <EditarMascotaPerdida petId={petId} />;
    } 
    else if(publicationType === 'me') {
        return <EditarMascotaEncontrada petId={petId} />;
    }
    else if(publicationType === 'ma') {
        return <EditarMascotaEnAdopcion petId={petId} />;
    }
}