import React from 'react';
import { Helmet } from "react-helmet";
import MainBanner from '../../components/Web/_MascotasEncontradas/MainBanner';
import Publicaciones from '../../components/Web/_MascotasEncontradas/Publicaciones';

export default function MascotasEncontradas() {
    return (
        <>
            <Helmet>
                <title>ENCONTRÉ UNA MASCOTA | Mascoteando</title>   
                <meta name="description" content="Publica un aviso para la mascota que pueda volver a casa."/>     
                <meta name="keywords" content="perro encontrado, mascota encontrada, gato encontrado, perro encontrado en Montevideo, perro encontrado en Uruguay, gato encontrado en Montevideo, gato encontrado en Uruguay"/>        
            </Helmet>

            <MainBanner />
            <Publicaciones />
        </>
    )
}