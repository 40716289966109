import React, { useState, useEffect } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { Row, Col, Card } from 'antd';
import queryString from 'query-string';
import { getMascotasEnAdopcionPaginationApi } from '../../../../api/mascotaEnAdopcion';
import { IMAGE_PATH } from '../../../../utils/constants';
import Pagination from '../../../Pagination';
import Filters from './Filters';

import './Publicaciones.scss';

function Publicaciones(props) {
    const { location, history } = props;
    const { page = 1 } = queryString.parse(location.search);
    const [ mascotasData, setMascotasData ] = useState(null);
    const [ reloadPublicaciones, setReloadPublicaciones ] = useState(null);
    const [ filtersData, setFiltersData ] = useState({});
    const { Meta } = Card; 
     


    useEffect(() => {
        getMascotasEnAdopcionPaginationApi(page, 9, filtersData.department, filtersData.neighborhood, filtersData.petType)
        .then(response => {
            setMascotasData(response.mp);
        });
        setReloadPublicaciones(false);
    }, [page, reloadPublicaciones, filtersData]);


    if(!mascotasData) {
        return null;
    }

    return (
        <div className="container-publicaciones"> 

            <Row>
                <Col lg={{ span: 16, offset: 4 }}>
                    <Filters filtersData={filtersData} setFiltersData={setFiltersData} />
                </Col>
            </Row>  

            {
            mascotasData.total === 0 ?                 
            
            <Row className='no-posts-message'>                
                <Col lg={{ span: 16, offset: 4 }}>   
                    <p>De momento no hay publicaciones de Mascotas en adopción</p>
                </Col>
            </Row> 
            :

            <Row>                
                <Col lg={{ span: 16, offset: 4 }}>                                
                    <Row className='row-publicaciones'  gutter={[50, 50]}>
                        {mascotasData.docs.map(item => {
                            
                            const aux = IMAGE_PATH + item.petImage[0];
                            
                            let finalized;
                            item.finalizedPublication === true ? finalized = 'finalizedPublication' : finalized = ''; 

                            return(
                                <Col lg={8} xs={24} key={item._id}>
                                    <Link to={`/mascotas-en-adopcion/mas-detalles/?id=${item._id}`}>
                                        <Card className={finalized} cover={<><img src={aux} alt={item.petName} /><PetNotice finalized={item.finalizedPublication} /></>}>
                                            { item.petName !== 'undefined' ?
                                                <Meta title={item.petName}/>
                                            :
                                                <br/>
                                            }
                                            <p>Sexo: {item.petSex}</p> 
                                            <p>Departamento: {item.department}</p>
                                            <p>Barrio: {item.neighborhood}</p>

                                            {/* Se muestran los datos solo si la publicación no fue finalizada */}
                                            {
                                            item.finalizedPublication === false ? 
                                            <>
                                                <p>Contacto: {item.contactName} {item.contactLastname}</p>
                                                <p>Teléfono: {item.phone}</p>
                                            </> 
                                            : 
                                                null 
                                            }
                                        </Card>   
                                    </Link>
                                </Col>
                            );
                        })}
                    </Row>            
                </Col>                
            </Row>   
            }

            <Pagination mascotasData={mascotasData} location={location} history={history} />   
        </div>
    )
}


function PetNotice (props) {
    const { finalized } = props;

    if(finalized ===  true) {
        return (
            <div className="ribbon ribbon-top-left">
                <span>Mascota adoptada</span>
            </div>
        )
    }
    else {
        return null;
    }    
}


export default withRouter(Publicaciones);