import React from 'react';
import { Helmet } from "react-helmet";
import { Link } from 'react-router-dom';
import RegisterUserForm from '../../components/Web/RegisterUserForm';
import { Row, Col, Divider, Button } from 'antd';
import LoginSignUpFacebook from '../../components/Web/LoginSignUpFacebook';

import './RegisterUser.scss';

export default function RegisterUser() {
    return (
        <>
            <Helmet>
                <title>Registro | Mascoteando</title>   
                <meta name="description" content="Registrate en Mascoteando. La web donde podrás publicar tu mascota perdida, una que encontraste o dar en adopción."/>             
            </Helmet>

            <Row className='register-user'>
                <Col md={4}/>
                    {/* Container */}
                    <Col md={16}>
                        <Row className='register-user-row'>                            
                            {/* Left container (white) */}
                            <Col md={12} className='register-user-left'>
                                <Row>
                                    <Col md={6}/>
                                        <Col md={12}>
                                            <h2>CREA TU CUENTA</h2>
                                            <RegisterUserForm />
                                            
                                            <Divider plain>O puedes:</Divider> 
                                            <Col md={24}>
                                                <LoginSignUpFacebook />
                                            </Col>
                                        </Col>
                                    <Col md={6}/>
                                </Row>
                            </Col>

                            {/* Right container (green) */}
                            <Col md={12} className='register-user-right'>
                                <Row>
                                    <Col md={6}/>
                                    <Col md={12} className='register-user-info'>
                                        <h2>INICIAR SESIÓN</h2>
                                        <p>Si ya tienes cuenta en el sitio puedes ingresas desde el botón de abajo</p>
                                        <Button><Link to="/acceso">IR A INICIO DE SESIÓN</Link></Button>
                                    </Col>
                                    <Col md={6}/>
                                </Row>
                            </Col>
                        </Row>
                    </Col>
                <Col md={4}/>
            </Row>
        </>
    )
}
