import React, { useState, useRef } from 'react';
import { Form, Input, Button, notification, Checkbox } from 'antd';
import { UserOutlined, MailOutlined, LockOutlined } from '@ant-design/icons';
import { emailValidation, minLengthValidation } from '../../../utils/formValidation';
import { signUpApi } from '../../../api/user';
import ReCAPTCHA from 'react-google-recaptcha';

import './RegisterUserForm.scss';

export default function RegisterUserForm() {  
    
    const recaptchaRef = useRef();
    const public_key = '6Le2flccAAAAADP1M2GepUPIHRY4ByzyqUmSeIU3';

    // Create an object with the values of the inputs
    const [ inputs, setInputs ] = useState({
        userName: '',
        email: '',
        password: '',
        repeatPassword: '',
        privacyPolicy: false
    });

    // Create an object with the validation values of the inputs
    const [ formValid, setFormValid ] = useState({
        userName: false,
        email: false,
        password: false,
        repeatPassword: false
    });

    // Update input object values while user types
    const changeForm = e => {
        if(e.target.name === 'privacyPolicy') {
            setInputs({
                ...inputs,
                [e.target.name] : e.target.checked                
            });
        }
        else {
            setInputs({
                ...inputs,
                [e.target.name] : e.target.value
            });
        }
    };

    // Inputs validation
    const inputValidation = e => {
        const { type, name } = e.target;

        if(type === 'email') {
            setFormValid({  ...formValid, [name]: emailValidation(e.target) });
        }

        if(type === 'password') {
            setFormValid({ ...formValid, [name]: minLengthValidation(e.target, 6) });
        }

        if(name === 'userName') {
            setFormValid({ ...formValid, [name]: minLengthValidation(e.target, 3) });
        }
    }

    const register = async () => {
        const userName = inputs.userName;
        const emailVal = inputs.email;
        const passwordVal = inputs.password;
        const repeatPasswordVal = inputs.repeatPassword;
        const privacyPolicyVal = inputs.privacyPolicy;

        // Recaptcha
        const tokenRecaptcha = await recaptchaRef.current.executeAsync();        
        recaptchaRef.current.reset();


        if(!privacyPolicyVal) {
            notification['error']({
                message: 'Debes aceptar las políticas de privacidad para poder registrarte'
            });
        }       
        else if(!userName || !emailVal || !passwordVal || !repeatPasswordVal) {
            notification['error']({
                message: 'Todos los campos son obligatorios'
            });
        }
        else{
            if(passwordVal !== repeatPasswordVal) {
                notification['error']({
                    message: 'Las contraseñas deben ser iguales'
                })
            }
            else if (!formValid.userName) {
                notification['error']({
                    message: 'El nombre debe tener al menos 3 letras'
                });
            }
            else if (!formValid.email) {
                notification['error']({
                    message: 'El formato de email es incorrecto'
                });
            }
            else if (!formValid.password || !formValid.repeatPassword) {
                notification['error']({
                    message: 'La contraseña debe tener al menos 6 caracteres'
                });
            }

            else {
               const result = await signUpApi(inputs, tokenRecaptcha);
               
               if(!result.ok) {
                   notification['error']({
                       message: result.message
                   });
               }
               else {
                    let redirect = "/registro/email-enviado";
                    window.location.href = redirect;
               }
            }
        }
        
    }



    return (
        <Form className='register-form' onChange={changeForm} onFinish={register}>
            <Form.Item>
                <Input prefix={<UserOutlined />} type='text' name='userName' placeholder='Ingresa tu nombre' className='register-form__input' value={inputs.userName} onChange={inputValidation}/>
                <Input prefix={<MailOutlined/>} type='email' name='email' placeholder='Correo electrónico' className='register-form__input' value={inputs.email} onChange={inputValidation}/>
                <Input prefix={<LockOutlined/>} type='password' name='password' placeholder='Contraseña' className='register-form__input' value={inputs.password} onChange={inputValidation}/>
                <Input prefix={<LockOutlined/>} type='password' name='repeatPassword' placeholder='Repetir contraseña' className='register-form__input' value={inputs.repeatPassword} onChange={inputValidation}/>
                
                <Form.Item>
                    <Checkbox name='privacyPolicy' checked={inputs.privacyPolicy}>
                        He leído y acepto la <a href='/politica-de-privacidad'>política de privacidad</a>.
                    </Checkbox>
                </Form.Item>

                <Form.Item>
                    <Button htmlType="submit" className="register-form__button"> 
                        REGISTRARME
                    </Button>
                </Form.Item>
            </Form.Item>
            <ReCAPTCHA 
                sitekey={public_key}    
                size='invisible'
                ref={recaptchaRef}                  
            />
        </Form>
    )
}
