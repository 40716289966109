import React from 'react';
import { Pagination as PaginationAntd } from 'antd';

import './Pagination.scss';


export default function Pagination(props) {
    const { mascotasData, location, history } = props;
    const currentPage = parseInt(mascotasData.page);
  
    const onChangePage = newPage => {
      history.push(`${location.pathname}?page=${newPage}`);
    }; 

    return (
        <PaginationAntd 
            defaultCurrent={currentPage}
            total={mascotasData.total}
            pageSize={mascotasData.limit}
            onChange={newPage => onChangePage(newPage)}
            className="pagination"
        />
    )
}