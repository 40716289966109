import React from 'react';
import { Helmet } from "react-helmet";
import { Link } from 'react-router-dom';
import LoginUserForm from '../../components/Web/LoginUserForm';
import LoginSignUpFacebook from '../../components/Web/LoginSignUpFacebook';
import { Row, Col, Divider, Button } from 'antd';


import './LoginUser.scss';

export default function LoginUser(props) {

    return (
        <>
            <Helmet>
                <title>Ingresar | Mascoteando</title>   
                <meta name="description" content="Mascoteando es la web donde podrás publicar tu mascota perdida, una que encontraste o dar en adopción."/>             
            </Helmet>

            <Row className='login-user'>
                <Col md={4}/>
                    {/* Container */}
                    <Col md={16}>
                        <Row className='login-user-row'>                            
                            {/* Left container (white) */}
                            <Col md={12} className='login-user-left'>
                                <Row>
                                    <Col md={6}/>
                                        <Col md={12}>
                                            <h2>INICIAR SESIÓN</h2>
                                            <LoginUserForm referrer={props.location.pathname} />
                                            
                                            <Col md={24} className='login-user-recuperar-contrasena'>
                                                <Link to="/recuperar-contrasena">¿Perdíste tu contraseña?</Link>
                                            </Col>

                                            <Divider plain>O puedes:</Divider>                                    
                                            <Col md={24}>
                                                <LoginSignUpFacebook />
                                            </Col>                                      
                                        </Col>
                                    <Col md={6}/>
                                </Row>
                            </Col>

                            {/* Right container (green) */}
                            <Col md={12} className='login-user-right'>
                                <Row>
                                    <Col md={6}/>
                                    <Col md={12} className='login-user-info'>
                                        <h2>CREA TU CUENTA</h2>
                                        <p>Si aún no te encuentras registrado en Soy Mascota, puedes crear tu cuenta y comenzar a publicar</p>
                                        <Button><Link to="/registro">IR A FORMULARIO DE REGISTRO</Link></Button>
                                    </Col>
                                    <Col md={6}/>
                                </Row>
                            </Col>
                        </Row>
                    </Col>
                <Col md={4}/>
            </Row>
        </>
    )
}