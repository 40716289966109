import React from 'react';
import { Route, Switch }  from 'react-router-dom';
import MenuTop from '../components/Web/MenuTop';
import Footer from '../components/Web/Footer';

import './LayoutPublic.scss';

export default function LayoutPublic(props) {
    const { routes } = props;

    return (
        <>
            {/* Menú  */}
            <MenuTop /> 

            {/* Page Content */}
            <LoadRoutes routes={routes} />

            {/* Footer */}
            <Footer />
        </>
    )
}

function LoadRoutes(props) {
    const { routes } = props;

    return (
        <Switch>
            {routes.map((route, index) => (
                <Route 
                    key={index}
                    path={route.path}
                    exact={route.exact}
                    component={route.component}
                />
            ))}
        </Switch>
    );     
}