import React from 'react';
import { Row, Col } from 'antd';


import './MainBanner.scss';

export default function MainBanner() {
    return (
        <div className='publicar-mascotas-perdidas-banner'>            
            <Row>
                <Col lg={{ span: 16, offset: 4 }}>
                    <h1 className="custom-title">Mascota en adopción</h1>
                    <p>
                        En esta sección podrás publicar un aviso en caso de que tengas una mascota que quieras dar en adopción.
                    </p>            
                </Col>
            </Row>            
        </div>
    );
}