import React from 'react';
import { Row, Col } from 'antd';

import './MainBanner.scss';


export default function MainBanner(props) {
    const { mascotaData } = props;
    const sexo = (mascotaData.petSex === 'Macho') ? 'encontrarlo' : 'encontrarla';

    return (
        <div className='detalles-mascota-banner'>            
            <Row>
                <Col lg={4} />
                <Col lg={16}>
                    <h1 className="custom-title">Se perdió {mascotaData.petName},<br/> ¡ayudanos a {sexo}!</h1>
                    <p>
                        Si crees haber visto a esta mascota en algún lugar, por favor ponte en contacto con la persona que lo busca. Más abajo contrarás sus datos.
                    </p>
                </Col>
                <Col lg={4} />
            </Row>            
        </div>
    );
}