import React from 'react';


import './Test.scss';


export default function Test() {

  return (
    <>
      <h1>TEST PAGE</h1>
    </>  
  )
}