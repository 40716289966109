import React from 'react';
import { Row, Col, Carousel, Badge } from 'antd';
import { FacebookOutlined, InstagramOutlined } from '@ant-design/icons';
import { WhatsappShareButton, WhatsappIcon, TwitterShareButton, TwitterIcon } from "react-share";
import { IMAGE_PATH } from '../../../../../utils/constants';

import './DetallesMascota.scss';


export default function DetallesMascota(props) {
    
    const { mascotaData } = props;

    
    return (       
        <div  className='detalles-mascota'>
            <Row>
                <Col lg={{ span: 10, offset: 7 }} >
                {
                    mascotaData.finalizedPublication ?
                    
                    // Publicación finalizada: Tiene Badge
                    <Badge.Ribbon text="Mascota adoptada" placement="start" >
                        <Carousel autoplay>
                            {
                                mascotaData.petImage?.map(item => {

                                    const finalized = mascotaData.finalizedPublication === true ? 'finalizedPublication' : '';
                                                            
                                    return(
                                        <div className='slide-img'>
                                            <img className={finalized} src={ IMAGE_PATH + item } alt="" />
                                        </div>                                
                                    );                            
                                })
                            }                   
                        </Carousel>               
                    </Badge.Ribbon> 

                    :
                    
                    // Publicación no finalizada: No tiene Badge
                    <Carousel autoplay>
                        {
                            mascotaData.petImage?.map(item => {
                                        
                                const finalized = mascotaData.finalizedPublication === true ? 'finalizedPublication' : '';

                                return(
                                    <div className='slide-img'>
                                        <img className={finalized} src={ IMAGE_PATH + item } alt="" /> 
                                    </div>                                
                                );                            
                            })
                        }                   
                    </Carousel> 
                    }
                </Col>
            </Row>

            <Row>
                <Col lg={{ span: 16, offset: 4 }} >

                <div className='share-buttons'>
                    <span className='share-title'>Compartir esta publicación: </span>
                        
                        <WhatsappShareButton
                            url={`https://mascoteando.com.uy/mascotas-perdidas/mas-detalles/?id=${mascotaData._id}`}
                            title={'Mascota perdida. ¡por favor, compartir!'}
                        >
                            <WhatsappIcon size={32} round />
                        </WhatsappShareButton>

                        <TwitterShareButton
                            url={`https://mascoteando.com.uy/mascotas-perdidas/mas-detalles/?id=${mascotaData._id}`}
                            title={'Mascota perdida. ¡por favor, compartir!\n\n'}
                            hashtags={['ayuda', 'mascotaPerdida']}
                        >
                            <TwitterIcon size={32} round />
                        </TwitterShareButton>

                    </div>                    
                    <br/>

                    { mascotaData.petName !== 'undefined' ?
                        <h2 align='center'>{mascotaData.petName}</h2>
                    :
                        null
                    }                    
                </Col>
            </Row>

            <Row className="detalles-mascota-adopcion_info">
                <Col lg={{ span: 16, offset: 4 }} >
                    <PrintData mascotaData={mascotaData} />
                </Col>
            </Row>
        </div>
    );
}


function PrintData(props) {
    const { mascotaData } = props;

    return (
        <>
            <h3>Mascota</h3>  
            { mascotaData.petType === 'Otro' ? 
                <p className='no-margin'>Tipo de mascota: { mascotaData.petTypeOther }</p>
                :
                null
            }
            <p className='no-margin'>Sexo: { mascotaData.petSex }</p>                  
            <p className='no-margin'>Edad: { mascotaData.petAge }</p>
            {
                mascotaData.petName !== 'undefined' ?
                <p className='no-margin'>Nombre: {mascotaData.petName }</p>
                :
                null
            }            
            <p className='no-margin'>Tamaño: { mascotaData.petSize }</p>

            <h3>Zona donde se encuentra</h3>
            <p className='no-margin'>Departamento: { mascotaData.department }</p>                  
            <p className='no-margin'>Barrio: { mascotaData.neighborhood }</p>                  
                              

            {/* Se muestran los datos solo si la publicación no fue finalizada */}
            {
            mascotaData.finalizedPublication === false ? 
            <>
                <h3>Contacto</h3>
                <p className='no-margin'>Contacto: { mascotaData.contactName } { mascotaData.contactLastname !== 'undefined' ? mascotaData.contactLastname : null }</p>  
                <p className='no-margin'>Teléfono: { mascotaData.phone }</p>  

                { mascotaData.comments !== 'undefined' ?
                <p className='no-margin last-line'>Comentarios: { mascotaData.comments }</p>
                :
                    null 
                }

                <h3>Publicaciones en redes sociales</h3>
                <div className='social-networks'>
                    <a href={mascotaData.facebookPost} target='_blank' rel='noreferrer'>
                        <FacebookOutlined /> Publicación en Facebook

                    </a>
                    <a href={mascotaData.instagramPost} target='_blank' rel='noreferrer'>
                        <InstagramOutlined /> Publicación en Instagram
                    </a>
                </div>
            </>
            :
                null
            }            
        </>
    );
}