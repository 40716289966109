import React from 'react';
import { Helmet } from "react-helmet";

export default function Error404() {
    return (
        <>
        <Helmet>
            <title>Página no encontrada | Mascoteando</title>   
        </Helmet>
        <div>
            <h2>Error 404!! </h2>
            <h1>=(</h1>
        </div>
        </>
    );
}