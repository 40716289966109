import React from 'react';
import { Helmet } from "react-helmet";
import MainBanner from '../components/Web/Home/MainBanner';
import CardsTipoPublicacion from '../components/Web/Home/CardsTipoPublicacion';
import CardsMascotasPerdidas from '../components/Web/Home/CardsMascotasPerdidas';
import CardsMascotasEncontradas from '../components/Web/Home/CardsMascotasEncontradas';
import CardsMascotasEnAdopcion from '../components/Web/Home/CardsMascotasEnAdopcion';

export default function Home() {
    return (
        <>
            <Helmet>
                <title>Inicio | Mascoteando</title>   
                <meta name="description" content="Mascoteando, la plataforma en línea de Uruguay, es el lugar perfecto para publicar información de tu mascota perdida, encontrada o para dar en adopción. Únete a nuestra comunidad de amantes de los animales y encuentra a tu compañero peludo hoy mismo."/>
                <meta name="keywords" content="perro perdido, perro encontrado, perros en adopción, adopción de perros, perros para adoptar, mascota perdida, mascota encontrada, mascota en adopción, gato perdido, gato encontrado, gatos para adoptar, perro perdido en Montevideo, perro perdido en Uruguay, gato perdido en Montevideo, gato perdido en Uruguay"/>
            </Helmet>

            <MainBanner />
            <CardsTipoPublicacion />
            <CardsMascotasPerdidas />
            <CardsMascotasEncontradas />
            <CardsMascotasEnAdopcion />
        </>
    )
}
