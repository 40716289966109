import React from 'react';
import { Row, Col } from 'antd';

import './MainBanner.scss';


export default function MainBanner(props) {
    const { mascotaData } = props;

    return (
        <div className='detalles-mascota-banner'>            
            <Row>
                <Col lg={4} />
                <Col lg={16}>
                    {
                        mascotaData.petName === 'undefined' ?
                        <h1 className="custom-title">Está buscando quien lo adopte!</h1>
                        : 
                        <h1 className="custom-title">{mascotaData.petName}, está buscando quien lo adopte!</h1>
                    }
                    
                    <p>
                        Si crees haber visto a esta mascota en algún lugar, por favor ponte en contacto con la persona que lo busca. Más abajo contrarás sus datos.
                    </p>
                </Col>
                <Col lg={4} />
            </Row>            
        </div>
    );
}