import React, { useState } from "react";
import { Row, Col, Form, Select } from 'antd';
import DataZones from '../../../../utils/DataZones.json';

export default function Filters(props) {
    const { filtersData, setFiltersData } = props;    
    const [form] = Form.useForm();
    const { Option } = Select;

    const arrayZones = DataZones;

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const optionsDepartments = [];
    optionsDepartments.push(<Option key={'Todos'} value={'Todos'}> { 'Todos' } </Option>);
    const [optionsNeighborhood, setoptionsNeighborhood] = useState([]);


    for(const item of arrayZones) {
        optionsDepartments.push(<Option key={item.departmentName} value={item.departmentName}> { item.departmentName } </Option>);
    }


    const chargeNeighborhood = e => {
        // Set department
        setFiltersData({ ...filtersData, department: e });
          
        const aux = [];

        form.setFieldsValue({ barrioSelect: undefined }); //reset barrio selection 


        setFiltersData(previousState => {
            return { ...previousState, neighborhood: undefined }
        }); 

        // Load neighborhood      
        for(const i of arrayZones) { 
            if(e === i.departmentName) {
                for(const j of i.neighborhood) {  
                    aux.push(<Option va lue={j} key={j} >{j}</Option>);
                }            
            }
        }
        setoptionsNeighborhood(aux);
    }


    return (
        <>
            <Form form={form}>
                <Row>

                    <Col lg={{span: 7 }} xs={24} >
                        <Form.Item
                            name="Departamento" 
                            label="Departamento"
                        >
                            <Select placeholder='Selecciona' onChange={chargeNeighborhood} value={filtersData.department} >
                                {optionsDepartments}
                            </Select>
                        </Form.Item>
                    </Col>

                    <Col lg={{span: 6, offset: 1 }} xs={24} >                        
                        <Form.Item
                            name="barrioSelect" 
                            label="Barrio"
                        >
                            <Select placeholder='Selecciona' onChange={e => setFiltersData({ ...filtersData, neighborhood: e })} value={filtersData.neighborhood} > 
                                {optionsNeighborhood}
                            </Select>
                        </Form.Item>                       
                    </Col>

                    <Col lg={{span: 7, offset: 1 }} xs={24} >
                        <Form.Item
                            name="tipoMascotaSelect" 
                            label="Tipo de Mascota"
                        >
                            <Select placeholder='Selecciona' onChange={e => setFiltersData({ ...filtersData, petType: e })} value={filtersData.petType}>
                                <Option key='Todos' value='Todos'>Todos</Option>
                                <Option key='Perro' value='Perro'>Perro</Option>
                                <Option key='Gato' value='Gato'>Gato</Option>
                                <Option key='Otro' value='Otro'>Otro</Option>
                            </Select> 
                        </Form.Item>
                    </Col>
                    
                </Row>
            </Form>
        </>
    )
}