import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Row, Col, Card } from 'antd';
import { getMascotasEncontradasApi } from '../../../../api/mascotaEncontrada';
import { IMAGE_PATH } from '../../../../utils/constants';

import './CardsMascotasEncontradas.scss'
export default function CardsMascotasEncontradas() {
    const [ mascotasData, setMascotasData ] = useState([]);

    useEffect(() => {
        getMascotasEncontradasApi().then(response => {
            const arrayMascotas = [];
            response.mp.forEach(item => {
                arrayMascotas.push(item);
            });
            setMascotasData(arrayMascotas);
        });
    }, []);


    if(!mascotasData) {
        return null;
    }


    return (
        mascotasData.length !== 0 ?
            <div className="container-publicaciones">      
                <Row>                
                    <Col lg={{ span: 16, offset: 4 }}>
                        <Row>                                 
                            <h2>Últimas mascotas que se encontraron perdidas por la calle</h2>   
                            <Link to={`/mascotas-encontradas/`} className='ver-todas'>Ver Todas</Link>   
                        </Row> 
                        <Row className='row-publicaciones'  gutter={[15, 15]}>
                            {mascotasData.map(item => {
                                
                                const aux = IMAGE_PATH + item.petImage[0];

                                let finalized;
                                item.finalizedPublication === true ? finalized = 'finalizedPublication' : finalized = ''; 

                                return(
                                    <Col lg={6} xs={24} key={item._id}>
                                        <Link to={`/mascotas-encontradas/mas-detalles/?id=${item._id}`}>
                                            <Card className={finalized} cover={<><img src={aux} alt={item.petName} /><PetNotice finalized={item.finalizedPublication} /></>}>
                                                <p>Sexo: {item.petSex}</p> 
                                                <p>Departamento: {item.department}</p>
                                                <p>Barrio: {item.neighborhood}</p>

                                                {/* Se muestran los datos solo si la publicación no fue finalizada */}
                                                {
                                                item.finalizedPublication === false ? 
                                                <>
                                                    <p>Contacto: {item.contactName} {item.contactLastname}</p>
                                                    <p>Teléfono: {item.phone}</p>
                                                </> 
                                                : 
                                                    null 
                                                }
                                            </Card>   
                                        </Link>
                                    </Col>
                                );
                            })}
                        </Row>            
                    </Col>                
                </Row>            
            </div>
        :
            null
    )
}

function PetNotice (props) {
    const { finalized } = props;

    if(finalized ===  true) {
        return (
            <div className="ribbon ribbon-top-left">
                <span>Mascota devuelta</span>
            </div>
        )
    }
    else {
        return null;
    }    
}