import React, { useState } from 'react';
import { Form, Input, Button, notification, Col } from 'antd';
import { LockOutlined } from '@ant-design/icons';
import { minLengthValidation } from '../../../utils/formValidation';
import { recoverUserPassworApi } from '../../../api/user';

import './RecoverUserPasswordForm.scss';

export default function RecoverUserPasswordForm(props) {  
    const { token } = props;

    // Create an object with the values of the inputs
    const [ inputs, setInputs ] = useState({
        newPassword: '',
        repeatPassword: ''
    });

    // Create an object with the validation values of the inputs
    const [ formValid, setFormValid ] = useState({
        newPassword: false,
        repeatPassword: false
    });

    // Update input object values while user types
    const changeForm = e => {
        setInputs({
            ...inputs,
            [e.target.name] : e.target.value
        });
    };

    // Inputs validation
    const inputValidation = e => {
        const { type, name } = e.target;

        if(type === 'password') {
            setFormValid({ ...formValid, [name]: minLengthValidation(e.target, 6) });
        }
    }

    const update = async () => {        
        const newPasswordVal = inputs.newPassword;
        const repeatPasswordVal = inputs.repeatPassword;
        
        if(!newPasswordVal || !repeatPasswordVal) {
            notification['error']({
                message: 'Todos los campos son obligatorios'
            });
        }
        else{
            if(newPasswordVal !== repeatPasswordVal) {
                notification['error']({
                    message: 'Las contraseñas deben ser iguales'
                })
            }
            else {
                const result = await recoverUserPassworApi(token, inputs);

                if(result.success !== true) {
                    notification['error']({
                        message: result.message
                    });
                }
                else {
                    notification['success']({
                        message: result.message
                    });
                    resetForm();
                }
            }
        }
        
    }

    const resetForm = () => {
        const inputs = document.getElementsByTagName('input');

        for(let i=0; i<inputs.length; i++){
            inputs[i].classList.remove('success');
            inputs[i].classList.remove('error');
        }

        setInputs({
            newPassword: '',
            repeatPassword: ''            
        });

        setFormValid({
            newPassword: false,
            repeatPassword: false            
        });
    };


    return (
        <>
            <Col lg={{span: 7, offset: 13 }} xs={{span: 22, offset: 1 }}>     
                <h2>CAMBIA TU CONTRASEÑA</h2> 
            </Col>
            <Form className='update-form' onChange={changeForm} onFinish={update}>
                <Form.Item>
                    
                    <Col lg={{span: 7, offset: 13 }} xs={{span: 22, offset: 1 }}>     
                        <Input prefix={<LockOutlined/>} type='password' name='newPassword' placeholder='Nueva Contraseña' className='register-form__input' value={inputs.newPassword} onChange={inputValidation}/>
                    </Col>
                    
                    <Col lg={{span: 7, offset: 13 }} xs={{span: 22, offset: 1 }}>     
                        <Input prefix={<LockOutlined/>} type='password' name='repeatPassword' placeholder='Repetir contraseña' className='register-form__input' value={inputs.repeatPassword} onChange={inputValidation}/>
                    </Col>

                    <Form.Item>
                        <Col lg={{span: 7, offset: 13 }} xs={{span: 22, offset: 1 }}>     
                            <Button htmlType="submit" className="register-form__button"> 
                                ACTUALIZAR CONTRASEÑA
                            </Button>
                        </Col>
                    </Form.Item>
                </Form.Item>    
            </Form>
        </>
    )
}
