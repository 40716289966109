import React from 'react';
import { Helmet } from "react-helmet";
import { useLocation, Route } from 'react-router-dom';
import UserActivationMessage from '../../components/Web/UserActivationMessage'; 

import LoginUser from './../LoginUser';

export default function UserActivation() {
  const token = useLocation().search.replace('?token=', '');  
  
  return (
    <Route render={props => (
      token ? 
      (
        <>            
        <Helmet>
            <title>Activación de cuenta | Mascoteando</title>                   
        </Helmet>

          <UserActivationMessage token={token} />
        </>
      ) 
      : 
      (
        <LoginUser {...props}/>
      )
    )}
    />      
  )
}