import React from 'react';
import { Helmet } from "react-helmet";
import { Col, Row } from 'antd';
import { MailOutlined } from '@ant-design/icons';

import './Contacto.scss';

export default function Contacto() {
    return (
        <>
        <Helmet>
            <title>Contacto | Mascoteando</title>   
            <meta name="description" content="Por cualquier consulta o comentario, te podés poner en contacto por medio de contacto@mascoteando.com.uy"/>             
        </Helmet>

        <Row className='contact-container'>
                <Col md={4}/>
                    {/* Container */}
                    <Col md={16}>
                        <Row className='contact-row'>                            
                            {/* Left container (white) */}
                            <Col md={12} className='contact-left'>
                                <Row>
                                    <Col md={6}/>
                                        <Col md={12}>
                                            <h2>CONTACTO</h2>
                                            
                                            <p>Por dudas, comentarios o sugerencias no dudes en contactarnos:<br/> 
                                                <a href='mailto:contacto@mascoteando.com.uy'>contacto@mascoteando.com.uy</a> 
                                            </p>      
                                        </Col>
                                    <Col md={6}/>
                                </Row>
                            </Col>

                            {/* Right container (green) */}
                            <Col md={12} className='contact-right'>
                                <Row>
                                    <Col md={6}/>
                                    <Col md={12} className='contact-info'>
                                        <MailOutlined/>
                                    </Col>
                                    <Col md={6}/>
                                </Row>
                            </Col>
                        </Row>
                    </Col>
                <Col md={4}/>
            </Row>
        </>
    )
}
