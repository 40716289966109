import React from 'react';
import { Helmet } from "react-helmet";
import { useLocation, Route } from 'react-router-dom';
import SendRecoveryEmailForm from '../../components/Web/SendRecoveryEmailForm';
import RecoverUserPasswordForm from '../../components/Web/RecoverUserPasswordForm';
import { Col } from 'antd';

import './RecoverUserPassword.scss';

export default function RecoverUserPassword() {
  const token = useLocation().search.replace('?token=', '');  

  return (
    <Route render={props => (
      !token ? 
        (
          <>
            <Helmet>
                <title>Recuparación de contraseña | Mascoteando</title>                   
            </Helmet>

            <div className='recover-password-container'>      
              <Col lg={4} />
              <Col lg={16}>         
                <SendRecoveryEmailForm />
              </Col>              
            </div>  
          </>
        )             
        : 
        (
          <>
            <Helmet>
                <title>Recuparación de contraseña | Mascoteando</title>                   
            </Helmet>

            <div className='recover-password-container'>      
              <Col lg={4} />
              <Col lg={16}> 
              <RecoverUserPasswordForm token={token} />
              </Col>              
            </div>  
          </>
        )
      )}
    />  
  )
}
