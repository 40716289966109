import React from 'react';
import { Row, Col } from 'antd';
import { MailOutlined } from '@ant-design/icons';


import './EmailMessage.scss';

export default function EmailMessage() {
  return (
    <>
        <Row className='container-email-message'>
            <Col lg={{ span: 24 }}>                                
                <Row className='row-email-message'>
                    <h1>Te enviamos un email a tu casilla de correo <MailOutlined /></h1>
                    <p>                        
                        Por favor, revisalo y hacé click en "activar mi cuenta" para poder 
                        hacer publicaciones en <span>Mascoteando</span>.
                    </p>
                </Row>            
            </Col>   
        </Row>
    </>
  )
}
