import React from 'react';
import { Row, Col } from 'antd';


import './MainBanner.scss';

export default function MainBanner(props) {

    const { publicationType } = props;

    return (
        <div className='publicar-mascotas-perdidas-banner'>            
            <Row>
                <Col lg={{ span: 16, offset: 4 }}>
                    <ConditionalRender publicationType = {publicationType} />
                </Col>
            </Row>            
        </div>
    );
}

function ConditionalRender(props) {
    const { publicationType } = props;
    
    if(publicationType === 'mp') {
        return (            
            <h1 className="custom-title">Edita los datos de la publicación de la mascota perdida</h1>                         
        )
    } 
    else if(publicationType === 'me') {
        return (
            <h1 className="custom-title">Edita los datos de la publicación de la mascota encontrada</h1>   
        )
    }
    else if(publicationType === 'ma') {
        return (
            <h1 className="custom-title">Edita los datos de la publicación de la mascota en adopción</h1>           
        )
    }
}