import React, { useState, useEffect, useRef } from 'react';
import { Row, Col, Form, Input, Select, Button, notification, Steps } from 'antd';
import { CloudUploadOutlined } from '@ant-design/icons';
import { addMascotaPerdidaApi } from '../../../../../api/mascotaPerdida';
import { getAccessTokenApi } from '../../../../../api/auth';
import SelectPetZone from './ZoneSelect';
import ReCAPTCHA from 'react-google-recaptcha';

import './PublicarForm.scss';

export default function PublicarForm(props) {
    const [ publicationData, setPublicationData ] = useState({});
    const [ imgObj, setImgObj ] = useState([]);
    const [ previewImg, setPreviewImg ] = useState([]);
    const recaptchaRef = useRef();
    const [form] = Form.useForm(); // Steps forms
    const { userId } = props;
    const public_key = '6Le2flccAAAAADP1M2GepUPIHRY4ByzyqUmSeIU3';


    const addPublication = async () => {
        const tokenRecaptcha = await recaptchaRef.current.executeAsync();        
        recaptchaRef.current.reset();
        const token = getAccessTokenApi();

        addMascotaPerdidaApi(token, publicationData.img, publicationData, userId, tokenRecaptcha)
            .then(response => {
                setPublicationData({}); 
                let url = "/mascotas-perdidas/mas-detalles/?id="  + response.message._id;
                window.location.href = url;

                notification['success']({
                    message: 'Publicación creada'
                });
            })
            .catch(err => {
                notification['error']({
                    message: err
                });
            })
    }

    useEffect(() => {
        if(imgObj.length > 0) {
            document.getElementById("error-file-count").style.display = "none";
        }

        if(imgObj) {
            setPublicationData({ ...publicationData, img: imgObj })     
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [imgObj]);


    // MEDIA QUERY TO STEPS NUMBERS
    function docReady(fn) {
        // see if DOM is already available
        if (document.readyState === "complete" || document.readyState === "interactive") {
            // call on next available tick
            setTimeout(fn, 1);
        } else {
            document.addEventListener("DOMContentLoaded", fn);
        }
    }   

    docReady(function() {
        if (window.innerWidth < 532) {    
            document.getElementById('steps-container').className = 'ant-steps ant-steps-horizontal ant-steps-label-horizontal';
        }
    });
  

    return (
        <div>
            <Row>
                <Col lg={4} />
                    <Col lg={16}>                        
                        <AddForm form={form} recaptchaRef={recaptchaRef} addPublication={addPublication} imgObj={imgObj} setImgObj={setImgObj} publicationData={publicationData} setPublicationData={setPublicationData} previewImg={previewImg} setPreviewImg={setPreviewImg}/>                                          
                    </Col>
                <Col lg={4} />
                <ReCAPTCHA 
                    sitekey={public_key}    
                    size='invisible'
                    ref={recaptchaRef}                  
                />
            </Row>   
        </div>
    )
}

/////////////////
//   AddForm   //
/////////////////
function AddForm(props) {

    const { form, publicationData, setPublicationData, addPublication, previewImg, setPreviewImg, imgObj, setImgObj } = props;
    
    
    const steps = [
        {
            title: "Paso 1",
            content: <Step1Form publicationData={publicationData} setPublicationData={setPublicationData} previewImg={previewImg} setPreviewImg={setPreviewImg} imgObj={imgObj} setImgObj={setImgObj} />
        },
        {
            title: "Paso 2",
            content: <Step2Form publicationData={publicationData} setPublicationData={setPublicationData} form={form}/>
        },
        {
            title: "Paso 3",
            content: <Step3Form publicationData={publicationData} setPublicationData={setPublicationData}  />
        }
    ];


    return (
        <StepPanel form={form} steps={steps} publicationData={publicationData} addPublication={addPublication} imgObj={imgObj}/>
    )
}




const Step1Form = (props) => {
    const { publicationData, setPublicationData, previewImg, setPreviewImg, imgObj, setImgObj } = props;
    return (
      < >
        <Col lg={{span: 10, offset: 7 }} xs={24}>        
            <h2 align='center' className='step-title'>Datos sobre tú mascota:</h2>
        </Col>
        <FormPetData publicationData={publicationData} setPublicationData={setPublicationData} previewImg={previewImg} setPreviewImg={setPreviewImg} imgObj={imgObj} setImgObj={setImgObj}/>
      </>
    );
};


const Step2Form = (props) => {
    const { publicationData, setPublicationData, form } = props;
    return (
      <>
        <Col lg={{span: 10, offset: 7 }} xs={24}>
            <h2 align='center' className='step-title'>Zona donde se perdió:</h2>
        </Col>
        <SelectPetZone publicationData={publicationData} setPublicationData={setPublicationData} form={form}/>
      </>
    );
};



const Step3Form = (props) => {
    const { publicationData, setPublicationData } = props;

    return (
      <>
        <Col lg={{span: 10, offset: 7 }} xs={24}>
            <h2 align='center' className='step-title'>Datos de contacto:</h2>
        </Col>
        <ContactData publicationData={publicationData} setPublicationData={setPublicationData}/>
      </>
    );
};





////////////////////////
//   Steps (logica)   //
////////////////////////
function StepPanel(props) {
    const [activeStep, setActiveStep ] = useState(0);
    const { form, addPublication, imgObj } = props;


    // Validate filds to click "Siguiente"
    function next() {
        let errImg = false;
        
        if(activeStep === 0) {
            // it is validated that at least one image has been uploaded 
            if(imgObj.length === 0) {
                errImg = true;
                document.getElementById("error-file-count").style.display = "block";
            }
            else {
                errImg = false;
                document.getElementById("error-file-count").style.display = "none";
            }
        }

        form.validateFields().then(values => {
            if(!errImg) {
                const nextStep = activeStep + 1;
                setActiveStep(nextStep);
            }
        }).catch(errorInfo => {
            console.log(errorInfo);
        });
    }
    
    function prev() {
        const prevStep = activeStep - 1;
        setActiveStep(prevStep);
    }


    return (
        <>
            <Form form={form} onFinish={addPublication} className='register-pet-lost' labelCol={{span: 24}}>
                <Col lg={{span: 10, offset: 7 }} xs={24} >
                    <Steps current={activeStep} direction={'horizontal'} id={'steps-container'}>
                        {props.steps.map((item) => (
                        <Steps.Step key={item.title} title={item.title} />
                        ))}
                    </Steps>
                </Col>
                
                <div className="steps-content">{props.steps[activeStep].content}</div>
                
                <Col lg={{span: 10, offset: 7 }} xs={24} >
                    <div className="steps-action">
                        {activeStep < props.steps.length - 1 && (
                        <Button type="primary" onClick={() => next()}>
                            Siguiente
                        </Button>
                        )}
                        {activeStep === props.steps.length - 1 && (
                        <Button  type="primary" htmlType="submit">
                            Publicar
                        </Button>
                        )}
                        {activeStep > 0 && (
                        <Button style={{ margin: "0 0 0 60px" }} onClick={() => prev()}>
                            Volver
                        </Button>
                        )}
                    </div>
                </Col>
            </Form>
        </>
    );
}

/////////////////////
//     Pet Data    //
/////////////////////
function FormPetData(props) {
    const { publicationData, setPublicationData, previewImg, setPreviewImg, imgObj, setImgObj } = props;
    const { Option } = Select;


    return(
        <>
            <Row>
                <Col lg={{span: 10, offset: 7 }} xs={24}>
                    <Form.Item 
                        name="TipoMascota"
                        label="Tu mascota es un..."
                        rules={[{ required: true, message: 'Seleciona el tipo de la mascota' }]}
                    >
                        <Select placeholder='Selecciona' onChange={e => setPublicationData({ ...publicationData, petType: e })} value={publicationData.petType} >
                            <Option value='Perro'>Perro</Option>
                            <Option value='Gato'>Gato</Option>
                            <Option value='Otro'>Otro</Option>
                        </Select>
                    </Form.Item>
                </Col>
            </Row>

            <Row id='container-tipo-mascota' >
                <Col lg={{span: 10, offset: 7 }} xs={24}  >
                    <Form.Item     
                        noStyle   
                        shouldUpdate={(prevValues, currentValues) =>
                            prevValues.TipoMascota !== currentValues.TipoMascota
                        }                        
                        
                    >
                        {({ getFieldValue }) => {
                            return getFieldValue("TipoMascota") === "Otro" ? (
                                <Form.Item
                                    name="customTipoMascota"
                                    label="Tipo de mascota"
                                    rules={[{ required: true, message: 'Ingresa el tipo de la mascota' }]}
                                    labelCol={{span: 24}}
                                >
                                    <Input placeholder='Ingresa el tipo de mascota' value={publicationData.petTypeOther} onChange={e => setPublicationData({ ...publicationData, petTypeOther: e.target.value })} />
                                </Form.Item>
                            )
                            : null;
                            }}
                    </Form.Item>
                </Col>
            </Row>

            <Row>
                <Col lg={{span: 10, offset: 7 }} xs={24} >
                    <Form.Item 
                        name="SexoMascota" 
                        label="Sexo de mascota"
                        rules={[{ required: true, message: 'Seleciona el sexo de la mascota' }]}
                    >
                        <Select placeholder='Selecciona' onChange={e => setPublicationData({ ...publicationData, petSex: e })} value={publicationData.petSex} >
                            <Option value='Hembra'>Hembra</Option>
                            <Option value='Macho'>Macho</Option>
                        </Select>
                    </Form.Item>
                </Col>
            </Row>

            <Row>
                <Col lg={{span: 10, offset: 7 }} xs={24} >
                    <Form.Item
                        name="EdadMascota" 
                        label="Edad de mascota"
                        rules={[{ required: true, message: 'Seleciona la edad de la mascota' }]}
                    >
                        <Select placeholder='Selecciona' onChange={e => setPublicationData({ ...publicationData, petAge: e })} value={publicationData.petAge} >
                            <Option value='Menos de 1 año'>Menos de 1 año</Option>
                            <Option value='De 1 a 5 años'>De 1 a 5 años</Option>
                            <Option value='De 5 a 10 años'>De 5 a 10 años</Option>
                            <Option value='Más de 10 años'>Más de 10 años</Option>
                        </Select>
                    </Form.Item>
                </Col>
            </Row>

            <Row>                    
                <Col lg={{span: 10, offset: 7 }} xs={24} className="upload-file-container">                        
                    <UploadImage previewImg={previewImg} setPreviewImg={setPreviewImg} imgObj={imgObj} setImgObj={setImgObj} />                    
                </Col>
            </Row>

            <Row>
                <Col lg={{span: 10, offset: 7 }} xs={24} >
                    <Form.Item 
                        name="NombreMascota" 
                        label="Nombre de mascota"
                        rules={[
                            { required: true, message: 'Ingresa el nombre de la mascota'}, 
                            { pattern: /^[a-zA-ZñÑáéíóúÁÉÍÓÚ\s]{2,50}$/, message: 'El nombre debe contener entre 2 y 50 caracteres' }
                        ]} 
                    >
                            <Input placeholder='Ingresa el nombre' value={publicationData.petName} onChange={e => setPublicationData({ ...publicationData, petName: e.target.value })} />
                    </Form.Item>                    
                </Col>
            </Row>
                        
            <Row>
                <Col lg={{span: 10, offset: 7 }} xs={24} >
                    <Form.Item
                        name="Tamaño" 
                        label="Tamaño de mascota"
                        rules={[{ required: true, message: 'Seleciona el tamaño de la mascota' }]}                    
                    >
                        {/* <FloatLabel label="Tamaño de mascota" valueInput={publicationData.petSize}> */}
                            <Select  placeholder='Seleciona' onChange={e => setPublicationData({ ...publicationData, petSize: e })} value={publicationData.petSize} >
                                <Option value='Chico'>Chico</Option>
                                <Option value='Mediano'>Mediano</Option>
                                <Option value='Grande'>Grande</Option>                            
                            </Select>
                        {/* </FloatLabel> */}
                    </Form.Item>
                </Col>
            </Row>    
        </>
    )
}



//////////////////////
//   Upload Image   //
//////////////////////
let counter = 0;
function UploadImage(props) {
    const { previewImg, setPreviewImg, setImgObj } = props;


    const checkSize = (e) => { 


        if(e.target.files.length > 0) {
            if (parseInt(e.target.files[0].size/1024) > 5120) {
                document.getElementById("error-file-size").style.display = "block";
            }
            else {
                counter++;
                // Check total of images
                if (counter <= 3) {            
                    document.getElementById("error-file-size").style.display = "none";
                    
                    
                    const filesArray = Array.from(e.target.files).map((file) =>
                        URL.createObjectURL(file)
                    );

                    
      
                    setImgObj(prevArray => [...prevArray, e.target.files]);  
                    setPreviewImg((prevImages) => prevImages.concat(filesArray));
                                
                    Array.from(e.target.files).map(
                        (file) => URL.revokeObjectURL(file) // avoid memory leak
                    );
                }
                else {
                    document.getElementById("error-file-count").style.display = "block";
                }
            }
        }
    };

    
    const renderPhotos = (source) => {
        return source.map((photo) => {
            return <img src={photo} alt="" key={photo} />;
        });
    };


   
    return (
      <div className="fileUpload">        
        <div>
            {/* <Form.Item     
                name="FotoMascota" 
                label="Fotos de mascota"
                rules={[
                    { required: true, message: 'Ingresa de una a tres imágenes de la mascota'}
                ]} 
            >                 
            </Form.Item> */}
            <input type="file" id="file-upload" accept=".jpg,.jpeg,png" onChange={(e)=> { checkSize(e) }} />            
            <label for="file-upload" className="custom-file-upload">
                <i className="fa fa-cloud-upload"><CloudUploadOutlined /></i> Fotos de la mascota
            </label>

            <div className="result">{renderPhotos(previewImg)}</div>
            
            <div className="ant-form-item-explain ant-form-item-explain-error" id="error-file-count" style={{display: 'none'}}><div role="alert">Ingresa al menos una imagen de la mascota</div></div>
            <div className="ant-form-item-explain ant-form-item-explain-error" id="error-file-size" style={{display: 'none'}}><div role="alert">Ninguna imagen no puede superar los 5MB</div></div>
            <div className="ant-form-item-explain ant-form-item-explain-error" id="error-file-count" style={{display: 'none'}}><div role="alert">La cantidad límite de imágenes de es 3</div></div>

        </div>
      </div>
    );
}






/////////////////////
//   Cantact Info  //
/////////////////////
function ContactData(props) {
    const { publicationData, setPublicationData } = props;
    const { TextArea } = Input;

    return (
        <>
            <Row>
                <Col lg={{span: 10, offset: 7 }} xs={24}>
                    <Form.Item
                        name="NombreContacto" 
                        label="Nombre del contacto"
                        rules={[
                                { required: true, message: 'Ingresa el nombre del contacto'}, 
                                { pattern: /^[a-zA-ZñÑáéíóúÁÉÍÓÚ\s]{2,50}$/, message: 'El nombre debe contener entre 2 y 50 caracteres' }
                            ]} 
                    >
                        <Input placeholder='Nombre' value={publicationData.contactName} onChange={e => setPublicationData({ ...publicationData, contactName: e.target.value })}/>
                    </Form.Item>    
                </Col>   

                <Col lg={{span: 10, offset: 7 }} xs={24}>
                    <Form.Item
                        name="ApellidoContacto" 
                        label="Apellido del contacto"
                        rules={[
                            { message: 'Ingresa el apellido del contacto'}, 
                            { pattern: /^[a-zA-ZñÑáéíóúÁÉÍÓÚ\s]{2,50}$/, message: 'El apellido debe contener entre 2 y 50 caracteres' }
                        ]}                     
                    >
                        <Input placeholder='Apellido' value={publicationData.contactLastname} onChange={e => setPublicationData({ ...publicationData, contactLastname: e.target.value })}/>
                    </Form.Item>    
                </Col>   
                <Col lg={{span: 10, offset: 7 }} xs={24}>
                    <Form.Item
                        name="TelContacto" 
                        label="Teléfono del contacto"
                        rules={[
                            { required: true, message: 'Ingresa el Teléfono del contacto'}, 
                            { pattern: /^[0-9]{3,30}$/, message: 'El teléfono debe tener entre 3 y 10 números' }
                        ]} 
                    >
                        <Input placeholder='Ingresa teléfono o celular del contacto' value={publicationData.phone} onChange={e => setPublicationData({ ...publicationData, phone: e.target.value })}/>
                    </Form.Item>    
                </Col>  
            </Row>

            <Row>
                <Col lg={{span: 10, offset: 7 }} xs={24}>
                    <Form.Item
                        name="Comentarios" 
                        label="Comentarios adicionales (opcional)"
                        rules={[                                
                                { pattern: /^[a-zA-ZñÑáéíóúÁÉÍÓÚ0-9\s.,]{0,500}$/, message: 'Los comentarios deben tener menos de 500 caracteres' }
                            ]}                      
                    >
                        <TextArea placeholder="Ingresa comentarios adicionales en caso de querer dar más detalles" autoSize={{ minRows: 3, maxRows: 5 }} value={publicationData.comments} onChange={e => setPublicationData({ ...publicationData, comments: e.target.value })}/>
                    </Form.Item>    
                </Col>  
            </Row>
        </>
    )
}