import React, {useEffect} from 'react';
import { ACCESS_TOKEN, REFRESH_TOKEN } from '../../../utils/constants';
import { Button } from 'antd';
import { FacebookOutlined } from '@ant-design/icons';
import { loginFacebookApi } from '../../../api/user';


export default function LoginSignUpFacebook() {

  const onLoginClick = () => {
      window.FB.login(function(res_login) {
        
        if (res_login.authResponse) {
         window.FB.api('/me?fields=id,name,email', function(res_me) {
          let access_token = res_login.authResponse.accessToken;

          res_me.accessToken = access_token;

          loginFacebookApi(res_me)
            .then(response => {
                console.log(response);

                const { accessToken, refreshToken } = response;
                localStorage.setItem(ACCESS_TOKEN, accessToken);
                localStorage.setItem(REFRESH_TOKEN, refreshToken);

                window.location.href ='/';
            })
            .catch((err) => {
                console.log(err);
            });
         });
        } else {
         console.log('User cancelled login or did not fully authorize.');
        }
    });
  };


  useEffect(() => {
      window.fbAsyncInit = () => {
          window.FB.init({
              appId            : '1089180122005151',
              autoLogAppEvents : true,
              xfbml            : true,
              version          : 'v14.0'
          });
      };
      (function (d, s, id) {
          var js, fjs = d.getElementsByTagName(s)[0];
          if (d.getElementById(id)) { return; }
          js = d.createElement(s); js.id = id;
          js.src = "https://connect.facebook.net/en_US/sdk.js";
          fjs.parentNode.insertBefore(js, fjs);
      }(document, 'script', 'facebook-jssdk'));
  }, []);

  return (
    <Button className='login-user-facebook' icon={<FacebookOutlined />}  onClick={onLoginClick}>Iniciar sesión con Facebook</Button>
  );
}