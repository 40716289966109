import React from 'react';
import { Row, Col, Button } from 'antd';
import { Link } from 'react-router-dom';
import { HeartFilled } from '@ant-design/icons';

import './MainBanner.scss';

export default function MainBanner() {
    return (
        <div className='mascotas-perdidas-banner'>            
            <Row>
                <Col lg={{ offset: 4, span: 16 }}>
                    <h1 className="custom-title">Mascotas en adopción<HeartFilled /></h1>
                    <p>
                        En esta sección podrás publicar un aviso en caso de que tu mascota se encuentre perdida. 
                        Además, ver las  mascotas que los usuarios han ido reportando como perdidas en todo el país.
                        ¡Esperamos poder ayudarte en la búsqueda!
                    </p>
                    <Row>
                        <Col lg={{ offset: 9, span: 6 }} xs={{ offset:4, span:16 }}>
                            <Button htmlType="submit" className="custom-button"> 
                                <Link to="/mascotas-en-adopcion/publicar">
                                    CREAR PUBLICACIÓN
                                </Link>
                            </Button>
                        </Col>
                    </Row>
                </Col>
            </Row>            
        </div>
    );
}