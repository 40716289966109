import React from 'react';
import { Helmet } from "react-helmet";
import MainBanner from '../../components/Web/_MascotasPerdidas/MainBanner';
import Publicaciones from '../../components/Web/_MascotasPerdidas/Publicaciones';

export default function MascotasPerdidas() {
    return (
        <>        
            <Helmet>
                <title>Mascotas Perdidas | Mascoteando</title>   
                <meta name="description" content="Si perdiste a tu mascota, podés publicar un aviso y así otros usuarios ayudarte en la búsqueda."/>             
                <meta name="keywords" content="perro perdido, mascota perdida, gato perdido, perro perdido en Montevideo, perro perdido en Uruguay, gato perdido en Montevideo, gato perdido en Uruguay"/>
            </Helmet>
        
            <MainBanner />
            <Publicaciones />
        </>
    )
}