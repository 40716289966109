import React from 'react';
import { Helmet } from "react-helmet";
import { Route  } from 'react-router-dom';
import UpdateUserPass from '../../components/Web/UpdateUserPassForm';
import { Col } from 'antd';

import useAuth from '../../hooks/useAuth';
import LoginUser from '../../pages/LoginUser';

import './UpdateUserPassword.scss';

export default function UserPassword() {
  const { user, isLoading } = useAuth();


  return (
    <Route render={props => (
      user && !isLoading ? 
        (
          <>
            <Helmet>
                <title>Cambiar contraseña | Mascoteando</title>                   
            </Helmet>
            
            <div className='update-password-container'>      
              <Col lg={4} />
              <Col lg={16}>         
                <UpdateUserPass userId={user.id} />
              </Col>              
            </div>
          </>
        )       
        : 
        (
            <LoginUser {...props}/>
        )
      )}
    />  
  )
}
