import React from 'react';
import { Helmet } from "react-helmet";
import { Row, Col } from 'antd';
import { BulbOutlined } from '@ant-design/icons';

import './Consejos.scss';

export default function Consejos() {
  return (
    <>
        <Helmet>
            <title>Consejos | Mascoteando</title>   
            <meta name="description" content="Consejos sobre búsqueda de mascotas."/>             
        </Helmet>

        <Row className='consejos-module'>                
            <Col lg={{ span: 16, offset: 4 }}>
              <h1 className="custom-title">Consejos <BulbOutlined /></h1>

              <h2>Búsqueda de Mascota</h2>
              <p>
                ¿Has perdido a tu mascota? ¡No te preocupes! Con un poco de planificación y perseverancia, es posible encontrar a 
                tu amado compañero. A continuación, te presentamos algunos consejos útiles para encontrar a tu mascota perdida:
              </p>

              <ul>
                <li>
                  Haz una búsqueda exhaustiva del área donde se perdió la mascota. Recorre tu vecindario y pregunta a tus vecinos si 
                  han visto a tu mascota. También puedes buscar en parques cercanos y lugares donde tu mascota suele jugar. 
                  No te rindas: a veces las mascotas pueden viajar grandes distancias, así que es importante seguir buscando incluso 
                  si parece que tu mascota ha desaparecido sin dejar rastro.
                </li>

                <li>
                  Poner carteles y avisos en lugares visibles y compartir la información en las redes sociales. Crea carteles con 
                  fotos de tu mascota y detalles como su nombre y el lugar donde se perdió. Colócalos en tiendas, parques y otros 
                  lugares donde la gente pase por allí. También puedes publicar en grupos de Facebook y otras redes sociales para 
                  avisar a la comunidad sobre tu mascota perdida.
                </li>

                <li>
                  Contacta con los refugios para mascotas para comentarles sobre tu mascota perdida. Es importante informar a estas 
                  organizaciones por si tu mascota es encontrada y llevada ahí. 
                </li>

                <li>
                  Ofrece una recompensa. A veces, ofrecer una recompensa puede ser una excelente manera de motivar a la gente a buscar a 
                  tu mascota. Asegúrate de publicar la recompensa en tus carteles y avisos, y de mencionarla en las redes sociales.
                </li>
              </ul>

              <p>
                Esperamos que estos consejos te ayuden a encontrar a tu mascota perdida. Recuerda que es importante no rendirse y 
                seguir buscando hasta que encuentres a tu amado compañero. Si quieres prevenir que tu mascota se pierda en el futuro, 
                asegúrate de mantener su collar con su nombre y tu información de contacto, y  microchipar a tu mascota para aumentar 
                sus posibilidades de ser devuelta a casa en caso de pérdida. ¡Buena suerte!
              </p>
            </Col>
        </Row>    
    </>
  )
}
