import React from 'react';
import { Helmet } from "react-helmet";
import { Route } from 'react-router-dom';
import { Row, Col } from 'antd';
import ListadoMascotas from '../../components/Web/_MisPublicaciones/ListadoMascotas';
import useAuth from '../..//hooks/useAuth';
import { getAccessTokenApi } from '../../api/auth';

import LoginUser from './../LoginUser';

export default function MisPublicaciones() {
    const { user, isLoading } = useAuth();
    const token = getAccessTokenApi();

    
    return (
        <Route render={props => (
                user && !isLoading ? 
                (
                    <>
                        <Helmet>
                            <title>Mis publicaciones | Mascoteando</title>                   
                        </Helmet>

                        <Row>                
                            <Col lg={{ span: 16, offset: 4 }}>
                                <h1 className='mis-publiaciones-title'>Mis publicaciones</h1>
                            </Col>
                        </Row>                        
                        <ListadoMascotas userId={user.id} token={token} />
                    </>
                ) 
                : 
                (
                    <LoginUser {...props}/>
                )
            )}
         />            
    )
}
