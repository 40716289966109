import React from 'react';
import { Layout, Row, Col } from 'antd';
import { FacebookOutlined, InstagramOutlined } from '@ant-design/icons';

import './Footer.scss';

export default function Footer() {
    const { Footer } = Layout;

    return (
        <Footer className='footer'>
            <Row>
                <Col md={4}/>
                <Col md={16}>
                    <Row>
                        <Col md={8}>
                           
                        </Col>
                        <Col md={8}>
                            
                        </Col>
                        <Col md={8}>
                            
                        </Col>
                    </Row>
                    <Row >
                        <Col md={12} xs={24} className='footer-info'>
                            <p><a href='mailto:contacto@mascoteando.com.uy'>contacto@mascoteando.com.uy</a></p>
                            <p><a href='/politica-de-privacidad'>Política de privacidad</a></p>
                            <p>Todos los derechos reservados M.D.O.</p>
                        </Col>

                        <Col md={12} xs={24} className='social-networks-icons'>            
                            <a href='https://www.facebook.com/Mascoteandouy-104745575323033' target='_blank' rel='noreferrer'><FacebookOutlined /></a>
                            <a href='https://www.instagram.com/mascoteandouy/' target='_blank' rel='noreferrer'><InstagramOutlined /></a>
                        </Col>
                    </Row>
                </Col>
                <Col md={4}/>
            </Row>
        </Footer>
    )
}
