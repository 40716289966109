import React from 'react';
import { Helmet } from "react-helmet";
import { Route  } from 'react-router-dom';
import MainBanner from '../../../components/Web/_MascotasEncontradas/_Publicar/MainBanner';
import PublicarForm from '../../../components/Web/_MascotasEncontradas/_Publicar/PublicarForm';
import useAuth from '../../../hooks/useAuth';

import LoginUser from '../../LoginUser';

import './Publicar.scss';

export default function Publicar() {

    // Hook
    const { user, isLoading } = useAuth();


    return (
        <Route render={props => (
                user && !isLoading ? 
                (
                    <>
                        <Helmet>
                            <title>Publicar mascota encontrada | Mascoteando</title>   
                            <meta name="description" content="En esta sección podrás publicar un aviso en caso de haber encontrado una mascota de alguien perdida por la calle."/>             
                        </Helmet>

                        <MainBanner />
                        <PublicarForm userId={user.id} />
                    </>
                ) 
                : 
                (
                    <LoginUser {...props}/>
                )
            )}
         />
    )
}