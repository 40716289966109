import React, { useState } from 'react';
import { Row, Col, Form, Select, Input } from 'antd';
import DataZones from '../../../../../utils/DataZones.json';


export default function SelectPetZone(props) {
    const { publicationData, setPublicationData, form } = props;
    const { Option } = Select;

    const arrayZones = DataZones;

    const optionsDepartments = [];
    const [optionsNeighborhood, setoptionsNeighborhood] = useState([]);


    for(const item of arrayZones) {
        optionsDepartments.push(<Option key={item.departmentName} value={item.departmentName}> { item.departmentName } </Option>);
    }


    const chargeNeighborhood = e => {
        // Set department
        setPublicationData({ ...publicationData, department: e });
          
        const aux = [];

        // Load neighborhood      
        for(const i of arrayZones) { 
            if(e === i.departmentName) {
                form.setFieldsValue({ barrioSelect: undefined }); //reset barrio selection

                for(const j of i.neighborhood) {  
                    if(j !==  'Todos') {
                        aux.push(<Option value={j} key={j} >{j}</Option>);
                    }
                }            
            }
        }
        setoptionsNeighborhood(aux);
    }



    return (
        <>
            {/* <Form form={form}> */}
                <Row>
                    <Col lg={{span: 10, offset: 7 }} xs={24} >
                        <Form.Item
                            name="Departamento" 
                            label="Departamento"
                            rules={[{ required: true, message: 'Seleciona un departamento' }]}
                        >
                            <Select placeholder='Selecciona' onChange={chargeNeighborhood} value={publicationData.department} >
                                {optionsDepartments}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col lg={{span: 10, offset: 7 }} xs={24} >
                        <Form.Item
                            name="barrioSelect" 
                            label="Barrio"
                            rules={[{ required: true, message: 'Seleciona un barrio' }]}
                        >
                            <Select placeholder='Selecciona' onChange={e => setPublicationData({ ...publicationData, neighborhood: e })} value={publicationData.neighborhood} > 
                                {optionsNeighborhood}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col lg={{span: 10, offset: 7 }} xs={24} >
                        <Form.Item
                            name="Esquina" 
                            label="Esquina"
                            rules={[
                                { required: true, message: 'Seleciona una esquina de referencia' },
                                { pattern: /^[a-zA-ZñÑáéíóúÁÉÍÓÚ0-9\s.,]{2,50}$/, message: 'La esquina debe contener entre 2 y 50 caracteres' }
                            ]}
                        >
                            <Input placeholder='Esquina' value={publicationData.address} onChange={e => setPublicationData({ ...publicationData, address: e.target.value })}/>
                        </Form.Item>                    
                    </Col>
                </Row>
            {/* </Form> */}
        </>
    )
}