import React, { useState, useEffect } from 'react';
import { Helmet } from "react-helmet";
import MainBanner from '../../../components/Web/_MascotasPerdidas/_MasDetalles/MainBanner';
import DetallesMascota from '../../../components/Web/_MascotasPerdidas/_MasDetalles/DetallesMascota';
import { useLocation } from 'react-router-dom';
import { getMascotaPerdidaApi } from '../../../api/mascotaPerdida';


export default function MasDetalles() {

    const [ mascotaData, setMascotaData ] = useState([]);
    const [ count, setCount ] = useState(0);
    const [ callApi, setCallApi ] = useState(null)

    const id = useLocation().search.replace('?id=', '');    
    
    // Llama API en un setInterval cada 1 segundo e incrementa el "count"
    useEffect(() => {
        setCallApi(
            setInterval(() => {

                getMascotaPerdidaApi(id)
                    .then(response => {     
                        setMascotaData(response);
                        setCount(count => count + 1);
                })                
            }, 1000)
        );        
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])


    // Cuando se cumplen las condiciones se activa el clearInterval de la llamada a la API
    useEffect(() => {
        if(count > 10 || (mascotaData.facebookPost !== undefined && mascotaData.instagramPost !== undefined)) {
            clearInterval(callApi);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [count])

    return (
        <>
            <Helmet>
                <title>Se Busca | Mascoteando</title>   
                <meta name="description" content="`Se busca esta mascota. Por favor, ayuda a difundirlo."/>                    
            </Helmet>
            
            <MainBanner mascotaData={mascotaData} />
            <DetallesMascota mascotaData={mascotaData} />
        </>
    )
}
