// Layout
import LayoutPublic from '../layouts/LayoutPublic';
// import LayoutClean from '../layouts/LayoutClean';

// Public Pages
import Home from '../pages/Home';
import Contacto from '../pages/Contacto';
import Consejos from '../pages/Consejos';

import Registro from '../pages/RegisterUser';
import EmailMessage from '../pages/RegisterUser/EmailMessage'
import Acceso from '../pages/LoginUser';
import Activacion from '../pages/UserActivation'
import ActualizarContrasena from '../pages/UpdateUserPassword';
import RecuperarContrasena from '../pages/RecoverUserPassword';
import PoliticasPrivacidad from '../pages/PrivacyPolicy';

import MascotasPerdidas from '../pages/MascotasPerdidas';
import PublicarMascotaPerdida from '../pages/MascotasPerdidas/Publicar';
import MasDetallesMascotasPerdidas from '../pages/MascotasPerdidas/MasDetalles';

import MascotasEncontradas from '../pages/MascotasEncontradas';
import PublicarMascotaEncontrada from '../pages/MascotasEncontradas/Publicar';
import MasDetallesMascotasEncontradas from '../pages/MascotasEncontradas/MasDetalles';

import MascotasEnAdopcion from '../pages/MascotasEnAdopcion';
import PublicarMascotaEnAdopcion from '../pages/MascotasEnAdopcion/Publicar';
import MasDetallesMascotasEnAdopcion from '../pages/MascotasEnAdopcion/MasDetalles';


import Test from '../pages/Test';

// Private Pages
import MisPublicaciones from '../pages/MisPublicaciones';
import EditarMisPublicaciones from '../pages/MisPublicaciones/EditarMisPublicaciones';

// Others
import Error404 from '../pages/Error404';

const routes = [
    // {
    //     path: '/registro',
    //     component: LayoutClean,
    //     exact: true,
    //     routes: 
    //     [
    //         {
    //             path: '/',
    //             component: Registro,
    //             exact: false
    //         }
    //     ],
    // },
    // {
    //     path: '/acceso',
    //     component: LayoutClean,
    //     exact: true,
    //     routes: 
    //     [
    //         {
    //             path: '/',
    //             component: Acceso,
    //             exact: false
    //         }
    //     ],
    // },
    {
        path: '/',
        component: LayoutPublic,
        exact: false,
        routes: 
        [               
            {
                path: '/test',
                component: Test,
                exact: true
            },           
            {
                path: '/',
                component: Home,
                exact: true
            },
            {
                path: '/contacto',
                component: Contacto,
                exact: true
            },
            {
                path: '/consejos',
                component: Consejos,
                exact: true
            },            
            {
                path: '/registro',
                component: Registro,
                exact: true
            },
            {
                path: '/registro/email-enviado',
                component: EmailMessage,
                exact: true
            },            
            {
                path: '/acceso',
                component: Acceso,
                exact: true
            },
            {
                path: '/activacion-de-usuario',
                component: Activacion,
                exact: true
            },
            {
                path: '/recuperar-contrasena',
                component: RecuperarContrasena,
                exact: true
            },            
            {
                path: '/cambiar-contrasena',
                component: ActualizarContrasena,
                exact: true
            },  
            {
                path: '/politica-de-privacidad',
                component: PoliticasPrivacidad,
                exact: true
            },             
            {
                path: '/mis-publicaciones',
                component: MisPublicaciones,
                exact: true
            },
            {
                path: '/mis-publicaciones/editar',
                component: EditarMisPublicaciones,
                exact: true
            },
            {
                path: '/mascotas-perdidas',
                component: MascotasPerdidas,
                exact: true
            },            
            {
                path: '/mascotas-perdidas/publicar',
                component: PublicarMascotaPerdida,
                exact: true
            },
            {
                path: '/mascotas-perdidas/mas-detalles',
                component: MasDetallesMascotasPerdidas,
                exact: true
            },
            {
                path: '/mascotas-encontradas',
                component: MascotasEncontradas,
                exact: true
            },
            {
                path: '/mascotas-encontradas/publicar',
                component: PublicarMascotaEncontrada,
                exact: true
            },
            {
                path: '/mascotas-encontradas/mas-detalles',
                component: MasDetallesMascotasEncontradas,
                exact: true
            },
            {
                path: '/mascotas-en-adopcion',
                component: MascotasEnAdopcion,
                exact: true
            },
            {
                path: '/mascotas-en-adopcion/publicar',
                component: PublicarMascotaEnAdopcion,
                exact: true
            },
            {
                path: '/mascotas-en-adopcion/mas-detalles',
                component: MasDetallesMascotasEnAdopcion,
                exact: true
            },               
            {
                component: Error404
            }
        ]
    }
]

export default routes;