import React from 'react'
import { Row, Col, Card } from 'antd';
import { Link } from 'react-router-dom';
import { SearchOutlined, EnvironmentFilled, HeartFilled } from '@ant-design/icons';

import './CardsTipoPublicacion.scss';

export default function CardsTipoPublicacion() {
    return (
        <Row className='tipo-publicacion'>
            <Col lg={24} className='tipo-publicacion__title'>
                <h2>¿En qué podemos ayudarte?</h2>
            </Col>

            <Col lg={{ span: 16, offset: 4 }}>
                <Row gutter={16} className='tipo-publicacion__row'>
                    <Col md={8} className='col-1'>
                        <div className='tipo-publicacion__circle'><SearchOutlined/></div> 
                        <CardPublicacion title='PERDÍ MI MASCOTA' description='Si perdiste a tu mascota publicá un aviso así otros usuarios pueden ayudarte en la búsqueda.' link='/mascotas-perdidas'/>
                    </Col>
                    <Col md={8} className='col-2'>
                        <div className='tipo-publicacion__circle'><EnvironmentFilled/></div> 
                        <CardPublicacion title='ENCONTRÉ UNA MASCOTA' description='Pública un aviso para que pueda volver a casa.' link='/mascotas-encontradas'/>
                    </Col>
                    <Col md={8} className='col-3'>
                        <div className='tipo-publicacion__circle'><HeartFilled /></div> 
                        <CardPublicacion title='BUSCO ALGUIEN QUE ADOPTE' description='Si quieres dar en adopción una mascota publica aquí.' link='/mascotas-en-adopcion'/>
                    </Col>   
                </Row>
            </Col>
        </Row>
        
    );
}


function CardPublicacion(props) {
    const { title, description, link } = props;
    const { Meta } = Card;

    return (
        <Link to={link}>
            <Card className='tipo-publicacion__card'>
                <Meta title={title} description={description} />
            </Card>
        </Link>
    );
}