import React from 'react';
import { Helmet } from "react-helmet";
import { Row, Col } from 'antd';

import './PrivacyPolicy.scss';

export default function PrivacyPolicy() {
  return (
    <>
      <Helmet>
          <title>Política de privacidad | Mascoteando</title>                   
      </Helmet>
      
      <Row className='container-privacy-policy'>
        <Col lg={4} />
        <Col lg={16}>   
          <h1>Política de privacidad</h1>
          <ol>
            
            <li>Mascoteando, propietario de este sitio Web, sólo recogerá información que contenga datos personales de usuarios 
              que brinden su consentimiento para ello.
            </li>

            <li>Mascoteando, tratará los datos personales proporcionados por los usuarios de este sitio, únicamente con la finalidad 
              y en la modalidad detallada en esta Política de Privacidad y en un todo de acuerdo a la normativa de la República 
              Oriental del Uruguay en materia de Protección de Datos Personales, en particular a la Ley No. 18.331 y los decretos 
              No. 664/008 de 22-12-008 y 414/009 de 31-8-009.
            </li>

            <li>Mascoteando, se compromete a usar los datos personales que reciba, exclusivamente para los fines específicos para los 
              cuales el usuario los proporciona. Sin perjuicio de ello, se reserva la posibilidad de realizar el tratamiento de 
              datos personales de los usuarios para utilizarlos en otros servicios de la empresa o de sus socios de negocios.
            </li>

            <li>Los datos personales podrán alojarse en los servidores que posee en otros países con un nivel de protección adecuado.</li>
            
            <li>La Política de Privacidad se limita a la utilización que el usuario haga directamente ingresando a la página Web, y 
              no se garantiza el mismo resultado en caso de que se acceda mediante el re direccionamiento de otros sitios.
            </li>
            
            <li>Los datos personales que se proporcionen se archivarán en una base de datos, los cuales se utilizaran para obtener 
              información estadística, para control de la empresa y/o comunicarnos con los usuarios.
            </li>

            <li>Esta Política de Privacidad detalla las prácticas en relación a los servicios que presta. Es por ello, que la misma 
              puede ser modificada en cualquier momento y a sola voluntad de Mascoteando si es que decide hacerlo.
            </li>

            <li>Mascoteando ha implementado todas las medidas de seguridad necesarias para evitar que terceros no autorizados accedan 
              a los datos. Ello incluye las medidas de seguridad físicas y el acceso sólo de empleados que necesiten acceder a los 
              datos por razones laborales, los cuales estarán sujetos a cláusulas de confidencialidad y obligaciones de secreto 
              profesional, contemplado en el Art. 302 del Código Penal.
            </li>

            <li>Mascoteando, hará los máximos esfuerzos para proteger los datos personales que le proporcionen los usuarios por 
              esta Web. Sin embargo, si en cumplimiento de obligaciones contractuales que asuma con terceros, o en el caso de 
              que exista una orden judicial al respecto, Mascoteando podrá revelar algunos de los datos que tenga en su poder.
            </li>

            <li>El Responsable de los datos obtenidos es Mascoteando, pudiéndose ejercer los derechos de acceso, modificación 
              desde el perfil de usuario o supresión dirigiéndose al e-mail <a href='mailto:contacto@mascoteando.com.uy'>
              contacto@mascoteando.com.uy</a> También podrá remitir el usuario, en la misma forma, las quejas o sugerencias que 
              entienda oportunas.
            </li>

            <li>Mascoteando utiliza cookies propias para mejorar los servicios relacionados con sus preferencias, mediante el 
              análisis de sus hábitos de navegación. Si continúa navegando, consideramos que acepta su uso. Usted puede configurar 
              su navegador para ser avisado de la recepción de las cookies o impedir su instalación.
            </li>
          </ol>
        </Col>   
      </Row>
    </>
  )
}
