import React, { useState } from 'react';
import { Form, Input, Button, notification, Col, Row } from 'antd';
import { Spin } from 'antd';
import { MailOutlined } from '@ant-design/icons';
import { sendRecoveryEmaildApi } from '../../../api/user';

import './SendRecoveryEmailForm.scss';

export default function SendRecoveryEmailForm() {  

    // Create an object with the values of the inputs
    const [ inputs, setInputs ] = useState({ email: '' });
    const [ sendingEmail, setSendingEmail ] = useState(false);


    // Update input object values while user types
    const changeForm = e => {
        setInputs({
            ...inputs,
            [e.target.name] : e.target.value
        });
    };


    const update = async () => {        
        const email = inputs.email;
        
        if(!email) {
            notification['error']({
                message: 'Todos los campos son obligatorios'
            });
        }
        else{           
            setSendingEmail(true);
            const result = await sendRecoveryEmaildApi(inputs);
            console.log('sendingEmail 1', sendingEmail);

            if(result.success !== true) {
                notification['error']({
                    message: result.message
                });
            }
            else {
                notification['success']({
                    message: result.message
                });
                
                resetForm();
                setSendingEmail(false);
                console.log('sendingEmail 2', sendingEmail);
            }            
        }
        
    }

    const resetForm = () => {
        const inputs = document.getElementsByTagName('input');

        for(let i=0; i<inputs.length; i++){
            inputs[i].classList.remove('success');
            inputs[i].classList.remove('error');
        }

        setInputs({
            email: ''        
        });
    };


    return (
        
        sendingEmail ?
            <Col lg={{span: 7, offset: 13 }} xs={{span: 22, offset: 1 }}>
                <Spin size="large" className='loader' />
            </Col>
        :

        <>
            <Col lg={{span: 7, offset: 13 }} xs={{span: 22, offset: 1 }}>     
                <h2>RECUPERA TU CONTRASEÑA</h2> 
            </Col>
            <Form className='update-form' onChange={changeForm} onFinish={update}>
                <Form.Item>
                    <Col lg={{span: 7, offset: 13 }} xs={{span: 22, offset: 1 }}>     
                        <Input prefix={<MailOutlined/>} type='email' name='email' placeholder='Correo electrónico' className='login-form__input' value={inputs.email}/>
                    </Col>
                    <Form.Item>
                        <Col lg={{span: 7, offset: 13 }} xs={{span: 22, offset: 1 }}>     
                            <Button htmlType="submit" className="register-form__button"> 
                                ENVIAR E-MAIL DE RECUPERACIÓN
                            </Button>
                        </Col>
                    </Form.Item>
                </Form.Item>    
            </Form>
        </>
    )
}
