import React, { useState, useEffect }  from 'react';
import { Link } from 'react-router-dom';
import useAuth from '../../../hooks/useAuth';
import ProfileMenu from './ProfileMenu';
import { Button } from 'antd';
import logo from '../../../assets/svg/logo_mascoteando.svg'

import './MenuTop.scss';
import './ProfileMenu/ProfileMenu.scss'


export default function MenuTop() {
  const [isOpen, setIsOpen] = useState(false);
  const { user, isLoading } = useAuth();


  useEffect(() => {        
    if(isOpen) {
      document.body.classList.add('body-overflow')
    }
    else {
      document.body.classList.remove('body-overflow')
    }
  }, [isOpen])
  

  return (
    <div className="Navbar">

      <div className={`nav-toggle ${isOpen && "open"}`} onClick={() => setIsOpen(!isOpen)}>
        <div className="bar"></div>
      </div>

      <Link to={'/'}><img className="nav-logo" src={logo} alt='Logo Mascoteando' /></Link>
      

      <div className={`nav-pages ${isOpen && "open"}`} onClick={() => setIsOpen(!isOpen)}>
        <Link to={'/'}>Inicio</Link>
        <Link to={'/contacto'}>Contacto</Link>
        <Link to={'/consejos'}>Consejos</Link>    
      </div>

      {user && !isLoading ?
        // Login menu
        <ProfileMenu user={user} />
        :
        // Logout menu   
        <div className={`nav-user ${isOpen && "open"}`} onClick={() => setIsOpen(!isOpen)}>
          <Link to={'/acceso'}>Acceder</Link> 
          <Button href="/registro" type="primary">Registrarse</Button>
        </div>
      }


      
    </div>
  )
}
