import React, { useState } from 'react';
import { Form, Input, Button, notification } from 'antd';
import { MailOutlined, LockOutlined } from '@ant-design/icons';
import { ACCESS_TOKEN, REFRESH_TOKEN } from '../../../utils/constants';
import { signInApi } from '../../../api/user';

import './LoginUserForm.scss';

export default function LoginUserForm(props) {
    const { referrer } = props;

    // Create an object with the values of the inputs
    const [ inputs, setInputs ] = useState({
        email: '',
        password: ''
    });

    // Update input object values while user types
    const changeForm = e => {
        setInputs({
            ...inputs,
            [e.target.name]: e.target.value  
        });        
    };

    const login = async e => {
        const result = await signInApi(inputs);
        
        // If exist a message it is because there is an error
        if(result.message) {
            notification['error']({
                message: result.message
            })
        }
        else {
            const { accessToken, refreshToken } = result;
            localStorage.setItem(ACCESS_TOKEN, accessToken);
            localStorage.setItem(REFRESH_TOKEN, refreshToken);

            notification['success']({
                message: 'Successful login'
            });
            
            referrer === '/acceso' ? window.location.href ='/'  : window.location.href = referrer;
        }
 
    };


    return (
        <Form className='login-form' onChange={changeForm} onFinish={login}>
            <Form.Item>
                <Input prefix={<MailOutlined/>} type='email' name='email' placeholder='Correo electrónico' className='login-form__input' />
                <Input prefix={<LockOutlined/>} type='password' name='password' placeholder='Contraseña' className='login-form__input' />
                <Form.Item className='login-form_container-button'>
                    <Button htmlType="submit" className="login-form__button"> 
                        ACCEDER
                    </Button>
                </Form.Item>
            </Form.Item>
        </Form>
    )
}
