import React, { useState, useEffect }  from 'react';
import { Link } from 'react-router-dom';
import { logout } from '../../../../api/auth'
import userIcon from '../../../../assets/png/user_profile.png';
import { UnorderedListOutlined, LockOutlined, PoweroffOutlined } from '@ant-design/icons';

import './ProfileMenu.scss'

export default function ProfileMenu(props) {

    const [isOpen, setIsOpen] = useState(false);
    const { user } = props;



    // Get Facebook SDK
    useEffect(() => {
        window.fbAsyncInit = () => {
            window.FB.init({
                appId            : '1089180122005151',
                autoLogAppEvents : true,
                xfbml            : true,
                version          : 'v14.0'
            });
        };
        (function (d, s, id) {
            var js, fjs = d.getElementsByTagName(s)[0];
            if (d.getElementById(id)) { return; }
            js = d.createElement(s); js.id = id;
            js.src = "https://connect.facebook.net/en_US/sdk.js";
            fjs.parentNode.insertBefore(js, fjs);
        }(document, 'script', 'facebook-jssdk'));
    }, []); 



    const logoutUser = () => {
        // Logout Facebook
        window.FB.getLoginStatus(function (response) {
            if (response.status === 'connected') {
                    window.FB.logout(function (response) {
                });
            } else {
                console.log("El usuario ya se encontraba deslogueado");
            }
        });  

        // Logout Site
        logout();
        window.location.reload();
    }

    // Cierra el menú cuando el usuario clickea fuera en cualquier parte de la pantalla.
    useEffect(() => {
        const handleClick = (event) => {
            const container = document.querySelector('.profile-container');
            if (!container.contains(event.target)) {
                setIsOpen(false);
            }
        }

        document.addEventListener('click', handleClick);

        return () => {
            document.removeEventListener('click', handleClick);
        }
    }, []);

    return (
        <>
            <div className="profile-container">
                <div className="profile" onClick={() => setIsOpen(!isOpen)}>
                    <img src={userIcon} alt=""/>
                </div>

                <div className={`menu ${isOpen && "active"}`}>
                    
                    <h3>
                        ¡Hola {user.userName}!       
                    </h3>

                    <ul>
                        <li>
                            <UnorderedListOutlined />
                            <Link to={'/mis-publicaciones'}>Mis publicaciones</Link>
                        </li>
                        <li>
                            <LockOutlined />
                            <Link to={'/cambiar-contrasena'}>Cambiar contraseña</Link>
                        </li>
                        <li>
                            <PoweroffOutlined />
                            <Link onClick={logoutUser}>Cerrar sesión</Link>
                        </li>
                    </ul>

                </div>
            </div>
        </>
    )
}
