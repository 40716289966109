import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Row, Col, Divider, Modal, notification } from 'antd';
import { List, Avatar } from 'antd';
import { getMascotasPerdidasUserApi, deleteMascotaPerdidaApi, finalizedPublicationMpApi } from '../../../../api/mascotaPerdida';
import { getMascotasEncontradasUserApi, deleteMascotaEncontradaApi, finalizedPublicationMeApi } from '../../../../api/mascotaEncontrada';
import { getMascotasEnAdopcionUserApi, deleteMascotaEnAdopcionApi, finalizedPublicationMaApi } from '../../../../api/mascotaEnAdopcion';
import { IMAGE_PATH } from '../../../../utils/constants';

import './ListadoMascotas.scss';

const { confirm } = Modal;

export default function ListadoMascotas(props) {
    const [ mascotasPerdidas, setMascotasPerdidas ] = useState([]);
    const [ mascotasEncontradas, setMascotasEncontradas ] = useState([]);
    const [ mascotasEnAdopcion, setMascotasEnAdopcion ] = useState([]);
    const [ reload, setReload ] = useState(false);
    const { userId, token } = props;


    // GET Mascotas Perdidas
    useEffect(() => {
        getMascotasPerdidasUserApi(userId, token).then(response => {
            const arrayMascotas = [];
            response.mp.forEach(item => {
                arrayMascotas.push(item);
            });
            setMascotasPerdidas(arrayMascotas);
        });
        setReload(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [reload]);


    // GET Mascotas Encontradas
    useEffect(() => {
        getMascotasEncontradasUserApi(userId, token).then(response => {
            const arrayMascotas = [];
            response.mp.forEach(item => {
                arrayMascotas.push(item);
            });
            setMascotasEncontradas(arrayMascotas);
        });
        setReload(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [reload]);


    // GET Mascotas en adopción
    useEffect(() => {
        getMascotasEnAdopcionUserApi(userId, token).then(response => {
            const arrayMascotas = [];
            response.mp.forEach(item => {
                arrayMascotas.push(item);
            });
            setMascotasEnAdopcion(arrayMascotas);
        });
        setReload(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [reload]);

    
    // DELETE Mascota Perdida
    const deleteMascotaPerdida = mascotasData => {
        confirm ({
            title: 'Eliminando publicación',
            content: `¿Estás seguro de eliminar la publicación de ${mascotasData.petName}?`,
            okText: 'Eliminar',
            okType: 'danger',
            cancelText: 'Cancelar',
            onOk() {
                deleteMascotaPerdidaApi(mascotasData._id, token)
                    .then(response => {
                        const typeNotification = response.code === 200 ? 'success' : 'Warning';
                        
                        notification[typeNotification]({
                            message: response.message
                        })

                        setReload(true);
                    })
                    .catch(() => {
                        notification['error']({
                            message: 'Error de servidor'
                        })
                    })
            }
        })
    }

    // DELETE Mascota Encontrada
    const deleteMascotaEncontrada = mascotasData => {
        confirm ({
            title: 'Eliminando publicación',
            content: `¿Estás seguro de eliminar la publicación de ${mascotasData.petName}?`,
            okText: 'Eliminar',
            okType: 'danger',
            cancelText: 'Cancelar',
            onOk() {
                deleteMascotaEncontradaApi(mascotasData._id, token)
                    .then(response => {
                        const typeNotification = response.code === 200 ? 'success' : 'Warning';
                        
                        notification[typeNotification]({
                            message: response.message
                        })

                        setReload(true);
                    })
                    .catch(() => {
                        notification['error']({
                            message: 'Error de servidor'
                        })
                    })
            }
        })
    }

    // DELETE Mascota en adopción
    const deleteMascotaEnAdopcion = mascotasData => {
        confirm ({
            title: 'Eliminando publicación',
            content: `¿Estás seguro de eliminar la publicación de ${mascotasData.petName}?`,
            okText: 'Eliminar',
            okType: 'danger',
            cancelText: 'Cancelar',
            onOk() {
                deleteMascotaEnAdopcionApi(mascotasData._id, token)
                    .then(response => {
                        const typeNotification = response.code === 200 ? 'success' : 'Warning';
                        
                        notification[typeNotification]({
                            message: response.message
                        })

                        setReload(true);
                    })
                    .catch(() => {
                        notification['error']({
                            message: 'Error de servidor'
                        })
                    })
            }
        })
    }

    

    const statusMascotaPerdida = mascotasData => {

        confirm ({
            title: 'Finalizar publicación',
            content: `¿Encontraste a tu mascota ${mascotasData.petName}? Una vez que marques la publicación como finalizada, la misma permanecerá publicada por 7 días pero tus datos de contacto ya no serán visibles.`,
            okText: 'Finalizar',
            okType: 'danger',
            cancelText: 'Cancelar',
            onOk() {
                finalizedPublicationMpApi(mascotasData._id, token)
                    .then(response => {
                        console.log(response);
                        const typeNotification = response.code === 200 ? 'success' : 'Warning';
                        
                        notification[typeNotification]({
                            message: response.message
                        })

                        setReload(true);
                    })
                    .catch(() => {
                        notification['error']({
                            message: 'Error de servidor'
                        })
                    })
            }
        })
    }

    const statusMascotaEncontrada = mascotasData => {

        confirm ({
            title: 'Finalizar publicación',
            content: `¿La mascota se reencontró con su amig@? Una vez que marques la publicación como finalizada, la misma permanecerá publicada por 7 días pero tus datos de contacto ya no serán visibles.`,
            okText: 'Finalizar',
            okType: 'danger',
            cancelText: 'Cancelar',
            onOk() {
                finalizedPublicationMeApi(mascotasData._id, token)
                    .then(response => {
                        console.log(response);
                        const typeNotification = response.code === 200 ? 'success' : 'Warning';
                        
                        notification[typeNotification]({
                            message: response.message
                        })

                        setReload(true);
                    })
                    .catch(() => {
                        notification['error']({
                            message: 'Error de servidor'
                        })
                    })
            }
        })
    }

    const statusMascotaEnAdopcion = mascotasData => {

        confirm ({
            title: 'Finalizar publicación',
            content: `¿Adoptaron la mascota? Una vez que marques la publicación como finalizada, la misma permanecerá publicada por 7 días pero tus datos de contacto ya no serán visibles.`,
            okText: 'Finalizar',
            okType: 'danger',
            cancelText: 'Cancelar',
            onOk() {
                finalizedPublicationMaApi(mascotasData._id, token)
                    .then(response => {
                        console.log(response);
                        const typeNotification = response.code === 200 ? 'success' : 'Warning';
                        
                        notification[typeNotification]({
                            message: response.message
                        })

                        setReload(true);
                    })
                    .catch(() => {
                        notification['error']({
                            message: 'Error de servidor'
                        })
                    })
            }
        })
    }

    if(!mascotasPerdidas && !mascotasEncontradas && !mascotasEnAdopcion) {
        return null;
    }

    return (
        <div className='container-mis-publicaciones'>                  
            <Row>                
                <Col lg={{ span: 16, offset: 4 }}>
                    
                    <Divider orientation='left' style={{fontSize: '1.4em', color: '#09d3ac'}}>Mascotas Perdidas</Divider>
                    <List
                        itemLayout='horizontal'
                        dataSource={mascotasPerdidas}
                        size='large'
                        renderItem={item => <ItemList item={item} statusPet={statusMascotaPerdida} deletePet={deleteMascotaPerdida} publicationType={'mp'} /> } 
                    />           

                    <Divider orientation='left' style={{fontSize: '1.4em', color: '#09d3ac'}}>Mascotas Encontradas</Divider>
                    <List
                        itemLayout='horizontal'
                        dataSource={mascotasEncontradas}
                        size='large'
                        renderItem={item => <ItemList item={item} statusPet={statusMascotaEncontrada} deletePet={deleteMascotaEncontrada} publicationType={'me'}/> } 
                    />      

                    <Divider orientation='left' style={{fontSize: '1.4em', color: '#09d3ac'}}>Mascotas en adopción</Divider>
                    <List
                        itemLayout='horizontal'
                        dataSource={mascotasEnAdopcion}
                        size='large'
                        renderItem={item => <ItemList item={item} statusPet={statusMascotaEnAdopcion} deletePet={deleteMascotaEnAdopcion} publicationType={'ma'}/> } 
                    />                          
                </Col>                
            </Row>            
        </div>
    )
}


function ItemList(props) {
    const { item, statusPet, deletePet, publicationType } = props; 
    
    const descriptionItem = (
        <p>
            <strong>Sexo</strong>: {item.petSex} -  <strong>Edad</strong>: {item.petAge}
            <br/>
            <strong>Departamento</strong>: {item.department} - <strong>Barrio</strong>: {item.neighborhood}
            <br/>
        </p>
    )

    let finalized;

    if(publicationType === 'mp') {
        finalized = '(Mascota encontrada)';
    }
    else if (publicationType === 'me') {
        finalized = '(Mascota devuelta)';
    }
    else if(publicationType === 'ma') {
        finalized = '(Mascota adoptada)';
    }


    let title;
    if(item.finalizedPublication) {
        title = item.petName === undefined ? finalized : item.petName + ' ' + finalized;
    }
    else {
        title = item.petName === undefined ? null : item.petName;
    }
    
    
    return (
        !item.finalizedPublication ?
        ( 
        <List.Item
            // eslint-disable-next-line jsx-a11y/anchor-is-valid
            actions={[            
                <SeePublication publicationType={publicationType} petId={item._id} />,   
                <Link to={`/mis-publicaciones/editar/?id=${item._id}&type=${publicationType}`}>Editar</Link>, 
                // eslint-disable-next-line jsx-a11y/anchor-is-valid
                <a onClick={() => statusPet(item)} ><TextStatus publicationType={publicationType} /></a>
                // eslint-disable-next-line jsx-a11y/anchor-is-valid
                // <a onClick={() => deletePet(item)}>Eliminar</a>
                
            ]}
        >
            <List.Item.Meta
                avatar={<Avatar src={IMAGE_PATH + item.petImage[0]} size={75} />}
                title={title}
                description={descriptionItem}
            />
        </List.Item>
        )
        :
        (
        <List.Item
        // eslint-disable-next-line jsx-a11y/anchor-is-valid
        actions={[            
            <SeePublication publicationType={publicationType} petId={item._id} /> 
        ]}
    >
        <List.Item.Meta
            avatar={<Avatar src={IMAGE_PATH + item.petImage[0]} size={75} />}
            title={title}
            description={descriptionItem}
        />
        </List.Item>
        )
    );
}

function SeePublication(props) {
    const { publicationType, petId } =  props;
    
    let path;

    if(publicationType === 'mp') {
        path = '/mascotas-perdidas/mas-detalles/?id=';
    }
    else if (publicationType === 'me') {
        path = '/mascotas-encontradas/mas-detalles/?id=';
    }
    else if(publicationType === 'ma') {
        path = '/mascotas-en-adopcion/mas-detalles/?id=';
    }

    return (
        <Link to={path + petId}>Ver publicación</Link> 
    )
}

function TextStatus(props) {
    const { publicationType } =  props;
    
    let text;

    if(publicationType === 'mp') {
        text = '¿Mascota encontrada?';
    }
    else if (publicationType === 'me') {
        text = '¿La mascota se reencontró?';
    }
    else if(publicationType === 'ma') {
        text = '¿Mascota adoptada?';
    }

    return text;
}