import { basePath, apiVersion } from './config';

export function addMascotaPerdidaApi(token, image, publicationData, userId, tokenRecaptcha) {
    const url = `${basePath}/${apiVersion}/add-mascota-perdida`;

    const formData = new FormData();

    Array.from(image).forEach(file => { 
        formData.append('image_file', file[0], file[0].name);
    });



    formData.append("petName", publicationData.petName);
    formData.append("petSex", publicationData.petSex);
    formData.append("petType", publicationData.petType);
    formData.append("petTypeOther", publicationData.petTypeOther);
    formData.append("petAge", publicationData.petAge);
    formData.append("petSize", publicationData.petSize);
    formData.append("department", publicationData.department);
    formData.append("neighborhood", publicationData.neighborhood);
    formData.append("address", publicationData.address);
    formData.append("contactName", publicationData.contactName);
    formData.append("contactLastname", publicationData.contactLastname);
    formData.append("phone", publicationData.phone);
    formData.append("comments", publicationData.comments);

    formData.append("userId", userId);

    formData.append("tokenRecaptcha", tokenRecaptcha);

    const params = {
        method: 'POST',
        body: formData,
        headers: {
            Authorization: token
        }
    }

    return fetch(url, params)
        .then(response => {
            return response.json();
        })
        .then(result => {
            return result;
        })
        .catch(err => {
            return err.message;
        })
}

export function getMascotasPerdidasApi() {
    const url = `${basePath}/${apiVersion}/get-mascotas-perdidas`;

    return fetch(url)
        .then(response => {
            return response.json();
        })
        .then(result => {
            return result;
        })
        .catch(err => {
            return err.message;
        });
}

export function getMascotasPerdidasPaginationApi(page, limit, department, neighborhood, petType) {
    let url;

    url = `${basePath}/${apiVersion}/get-mascotas-perdidas-pagination?page=${page}&limit=${limit}&department=${department}&neighborhood=${neighborhood}&petType=${petType}`;     

    return fetch(url)
        .then(response => {
            return response.json();
        })
        .then(result => {
            return result;
        })
        .catch(err => {
            return err.message;
        });    
}

export function getMascotaPerdidaApi(petId) {
    const url = `${basePath}/${apiVersion}/get-mascota-perdida/${petId}`;

    return fetch(url)
        .then(response => {
            return response.json();
        })
        .then(result => {
            return result.pet;
        })
        .catch(err => {
            return err.message;
        });
}

export function getMascotasPerdidasUserApi(userId, token) {
    const url = `${basePath}/${apiVersion}/get-mascotas-perdidas-user/${userId}`;


    const params = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            Authorization: token
        }
    }

    return fetch(url, params)
        .then(response => {
            return response.json();
        })
        .then(result => {
            return result;
        })
        .catch(err => {
            return err.message;
        });
}

export function deleteMascotaPerdidaApi(petId, token) {
    const url = `${basePath}/${apiVersion}/delete-mascota-perdida/${petId}`;

    const params = {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json',
            Authorization: token
        }
    }

    return fetch(url, params)
    .then(response => {
        return response.json();
    })
    .then(result => {
        return result;
    })
    .catch(err => {
        return err.message;
    });
}

export function updateMascotaPerdidaApi(petId, publicationData, token) {
    const url = `${basePath}/${apiVersion}/update-mascota-perdida/${petId}`;

    const params = {
        method: 'PUT',
        body: JSON.stringify(publicationData),
        // body: publicationData,
        headers: {
            'Content-Type': 'application/json',
            Authorization: token
        }
        
    }

    return fetch(url, params)
    .then(response => {
        return response.json();
    })
    .then(result => {
        return result;
    })
    .catch(err => {
        return err.message;
    });
}

export function finalizedPublicationMpApi(petId, token) {
    const url = `${basePath}/${apiVersion}/finalized-publication-mp/${petId}`;

    const params = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            Authorization: token
        }
    }

    return fetch(url, params)
    .then(response => {
        return response.json();
    })
    .then(result => {
        return result;
    })
    .catch(err => {
        return err.message;
    });
}
