import React from 'react';
import { Row, Col } from 'antd';

import './MainBanner.scss';

export default function MainBanner() {
    return (
        <div className='main-banner'>            
            <Row>
                <Col offset={4} lg={16} className="main-banner_container-title">
                    <h1 className="custom-title">Mascoteando</h1>
                    <p>
                        Uniendo corazones con patas
                    </p>
                </Col>
            </Row>            
        </div>
    );
}