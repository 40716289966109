import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Row, Col } from 'antd';
import { CheckCircleFilled, InfoCircleFilled } from '@ant-design/icons';
import { userActivationApi } from '../../../api/user'
import './UserActivationMessage.scss';


export default function UserActivationMessage(props) {
  const { token } = props;
  const [ activationStatus, setActivationStatus ] = useState(null);


  useEffect(() => {
    userActivationApi(token)
    .then(response => {
      setActivationStatus(response.status)
    })
    .catch(() => {
      console.log('Error token')
    });
  }, [token]);



  if(!activationStatus) {
    return null;
  }

  return (
    <div className="container-user-verification">
      <Row>                
        <Col lg={{ span: 24 }}>                                
            <Row className='row-user-verification'>
              <MessageRender activationStatus={activationStatus} />
            </Row>            
        </Col>                
      </Row>       
    </div>
  )
}

function MessageRender(props) {
  const { activationStatus } =  props;

  if(activationStatus === 200) {
    return (
    <>
      <h1>Tu cuenta se activó correctamente <CheckCircleFilled /></h1>
      <p>Podés loguearte desde <Link to="/acceso">aquí</Link></p>
    </>
    )
  }
  else if(activationStatus === 204) {
    return (
      <>
        <h1>Tu cuenta ya se encontraba activada <InfoCircleFilled /></h1>
        <p>Podés loguearte desde <Link to="/acceso">aquí</Link></p>
      </>
    )
  }
  else if(activationStatus === 400){
    return (
      <h1>El usuario que intentas activar no existe en el sistema</h1>
    )
  }
}