import React from "react";
import { BrowserRouter as Router, Route, Switch }  from 'react-router-dom';
import routes from './config/routes';
import AuthProvider from './providers/AuthProviders';
import ScrollToTop from './components/ScrollToTop';
import TagManager from 'react-gtm-module'

import './App.scss';


function App() {

  const tagManagerArgs = {
    gtmId: 'G-3HJL935RB5'
  }

  TagManager.initialize(tagManagerArgs);  

  return (
    <AuthProvider>
      <Router>
        <ScrollToTop />
        <Switch>
            {/* <>
              <h1>Página en Mantenimiento</h1>
            </>  */}
          {
            routes.map((r, index) => (
              <RouteWithSubRoutes key={index} {...r} />
            ))
          }
        </Switch>
      </Router>
    </AuthProvider>
  );
}

function RouteWithSubRoutes(r) {
  return (
    <Route 
      path={r.path} 
      exact={r.exact} 
      render={props => <r.component routes={r.routes} {...props} />} 
    />
  );
}

export default App;
